import React, {Component} from "react";
import './Dashboard.css';

//Components
import DataChart from "../../../../reusable/DataChart/DataChart";

class Dashboard extends Component {

    render() {

        return (
            <div id="dashboard" className='dashboard'>
                <h1>Willkommen {this.props.user !== null ? this.props.user.username : 'Lädt...'}!</h1>
                <div className="holder">
                    {this.props.formattedData ? <DataChart
                        formatDate={this.props.formatDate}
                        formattedData={this.props.formattedData}
                    /> : null}
                </div>
            </div>
        )
    }
}

export default Dashboard;