import React, {Component} from "react";
import './ChatDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {ChatRoom} from "./ChatRoom/ChatRoom";
import ChatOverview from "./ChatOverview/ChatOverview";

class ChatDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' maxWidth={"md"} fullWidth open={this.props.open} onClose={this.props.close} className='chat-dialog'>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle>
                    Chat
                </DialogTitle>
                <DialogContent>
                    {true && this.props.user ?
                        <ChatOverview
                            user={this.props.user}
                            chatData={this.props.chatData}
                            logout={this.props.logout}
                            showMessage={this.props.showMessage}
                            company={this.props.company}
                            formatDateTime={this.props.formatDateTime}
                            setNewMessage={this.props.setNewMessage}
                        /> : null
                    }
                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        )
    }
}

export default ChatDialog;