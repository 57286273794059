import React, {Component} from 'react';
import './TripSeriesOverview.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@mui/material/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import TripDialog from "../TripDialog/TripDialog";
import api from "../../../../../api";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import SecureYesCancelDialog from "../../../../../reusable/SecureYesCancelDialog/SecureYesCancelDialog";

class TripSeriesOverview extends Component {

    state = {
        shownTripSeries: [],
        searchWord:"",
        searchType:"patientLastName",
        activeCompany:"all",
        activeTripSeries:null,

        //Dialogs
        showTripDialog:false,
        showDeleteTripDialog:false
    }

    companySearchOnChangeHandler = (company) => {
        this.setState({activeCompany:company});
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchWord:"",searchType:e.target.value});
    }

    searchOnClick = () => {
        if (this.state.searchType !== null) {
            let shownTripSeries = [];
            switch (this.state.searchType) {
                case "patientFirstName":
                    this.props.tripSeries.forEach(tripSeries => {
                        if ((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === tripSeries.abbreviation) && tripSeries.patientFirstName && tripSeries.patientFirstName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTripSeries.push(tripSeries);
                        }
                    });
                    break;
                case "patientLastName":
                    this.props.tripSeries.forEach(tripSeries => {
                        if ((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === tripSeries.abbreviation) && tripSeries.patientLastName && tripSeries.patientLastName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTripSeries.push(tripSeries);
                        }
                    });
                    break;
                case "postCode":
                    this.props.tripSeries.forEach(tripSeries => {
                        if ((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === tripSeries.abbreviation) && tripSeries.postCode && tripSeries.postCode.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTripSeries.push(tripSeries);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownTripSeries: shownTripSeries});
        } else {
            this.props.showMessage(2, 'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    completeTripSeries = (tripSeries) => {
        const url = '/trip-series/complete';
        api.post(url,tripSeries)
            .then(response => {
                this.props.addCompletedTripToList(response.data);
                this.searchOnClick();
                this.closeTripDialog();
                this.props.showMessage(0,"Eine Fahrt der Serie wurde erfolgreich abgeschlossen");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    cancelTripSeries = (tripSeries) => {
        const url = '/trip-series/cancel';
        api.post(url,tripSeries)
            .then(response => {
                this.props.removeTripSeriesFromList(tripSeries);
                this.searchOnClick();
                this.closeTripDialog();
                this.props.showMessage(0,"Die Serie wurde erfolgreich abbestellt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    setTripSeriesOpened = (tripSeries) => {
        let url = "";
        if(this.props.company && this.props.company.rights === 1) {
            tripSeries.openedMaster = true;
            url = "/trip-series/opened-master";
        }else{
            tripSeries.openedCustomer = true;
            url = "/trip-series/opened-customer";
        }
        api.post(url,tripSeries)
            .then(response => {

            })
    }

    saveChangeRequest = (tripSeries) => {
        const url = '/trip-series/change-request';
        api.put(url,tripSeries)
            .then(response => {
                this.props.updateTripSeriesInList(response.data);
                this.searchOnClick();
                this.closeTripDialog();
                this.props.showMessage(0,"Die Änderungsanfrage wurde erfolgreich verschickt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    confirmChangedTripSeries = (tripSeries) => {
        let url = "/trip-series/change-request/trip-series/confirm";
        api.post(url,tripSeries)
            .then(response => {
                this.props.updateTripSeriesInList(response.data);
                this.searchOnClick();
                this.closeTripDialog();
                this.props.showMessage(0,"Die Änderungsanfrage wurde erfolgreich bestätigt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    rejectChangedTripSeries = (tripSeries) => {
        let url = "/trip-series/change-request/trip-series/reject";
        api.post(url,tripSeries)
            .then(response => {
                this.props.updateTripSeriesInList(response.data);
                this.searchOnClick();
                this.closeTripDialog();
                this.props.showMessage(0,"Die Änderungsanfrage wurde erfolgreich abgelehnt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteTripSeries = (tripSeries) => {
        console.log(tripSeries)
        let url = "/trip-series/" + tripSeries.abbreviation + "/" + tripSeries.id;
        api.delete(url,tripSeries)
            .then(response => {
                this.props.removeTripSeriesFromList(tripSeries);
                this.searchOnClick();
                this.closeDeleteItemDialog();
                this.props.showMessage(0,"Die Serienfahrt wurde erfolgreich gelöscht");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showTripDialog = (tripSeries) => {
        if(this.props.company && ((this.props.company.rights === 1 && !tripSeries.openedMaster) || (this.props.company.rights === 2 && !tripSeries.openedCustomer))) {
            this.setTripSeriesOpened(tripSeries);
            this.props.checkForNewTripSeries(this.props.company,this.props.tripSeries);
        }
        this.setState({activeTripSeries:tripSeries,showTripDialog:true});
    }

    closeTripDialog = () => {
        this.setState({showTripDialog:false});
    }

    showDeleteItemDialog = (tripSeries) => {
        this.setState({activeTripSeries:tripSeries,showDeleteItemDialog:true});
    }

    closeDeleteItemDialog = () => {
        this.setState({showDeleteItemDialog:false});
    }

    render() {

        return (
            <div className='trip-series-overview'>
                <ReactTooltip/>

                <TripDialog
                    open={this.state.showTripDialog}
                    close={this.closeTripDialog}
                    showMessage={this.props.showMessage}
                    trip={this.state.activeTripSeries}
                    countries={this.props.countries}
                    formatDate={this.props.formatDate}
                    completeTrip={this.completeTripSeries}
                    cancelTripSeries={this.cancelTripSeries}
                    type={"trip-series"}
                    saveChangeRequest={this.saveChangeRequest}
                    confirmChangedTrip={this.confirmChangedTripSeries}
                    rejectChangedTrip={this.rejectChangedTripSeries}
                    formatDateTime={this.props.formatDateTime}

                    //Company
                    company={this.props.company}

                    //Patients
                    patients={this.props.patients}

                    // Stations
                    stations={this.props.stations}
                    station={this.props.station}
                />

                <SecureYesCancelDialog
                    open={this.state.showDeleteItemDialog}
                    close={this.closeDeleteItemDialog}
                    header='Serienfahrt löschen?'
                    text='Wollen Sie die Serienfahrt wirklich bestätigen? Sie können dies nicht rückgängig machen.'
                    onClick={() => this.deleteTripSeries(this.state.activeTripSeries)}
                />

                <div className='header-bar'>
                    <h1>Serienfahrten</h1>
                    {this.props.company && this.props.company.rights === 1 ? <TextField value={this.state.activeCompany} onChange={(e) => this.companySearchOnChangeHandler(e.target.value)} select className='search-select field' label='Unternehmen'>
                        <MenuItem key={0} value={"all"}>Alle</MenuItem>
                        {this.props.companies ? this.props.companies.map((company,index) => (
                            <MenuItem key={index + 1} value={company}>{company.displayName}</MenuItem>
                        )) : []}
                    </TextField> : null}
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-select' label='Suchwort'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'patientFirstName'}>Vorname</MenuItem>
                        <MenuItem key={1} value={'patientLastName'}>Nachname</MenuItem>
                        <MenuItem key={2} value={'postCode'}>Postleitzahl</MenuItem>
                    </TextField>
                    <ToolButton disabled={this.props.loading} main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownTripSeries.map((tripSeries,index) => (
                        <ListItem
                            key={index}
                            type="trip-series"
                            deletable={true}
                            item={tripSeries}
                            showDeleteItemDialog={() => this.showDeleteItemDialog(tripSeries)}
                            showItemDialog={() => this.showTripDialog(tripSeries)}
                            formatDate={this.props.formatDate}
                            company={this.props.company}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default TripSeriesOverview;