import React, {Component} from "react";
import './ChangeRequestDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import ReactTooltip from "react-tooltip";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";

class ChangeRequestDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' fullWidth open={this.props.open} onClose={this.props.close} className='change-trip-dialog'>
                <ReactTooltip/>
                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1>Änderungsanfrage</h1></DialogTitle>
                <DialogContent>
                    {this.props.trip && this.props.trip.tripSeries ?
                    <div>
                        <DialogContentText>
                            {this.props.trip.endDate ? "Das aktuelle Enddatum ist der " + this.props.formatDate(this.props.trip.endDate) : "Aktuell liegt kein Enddatum vor"}
                        </DialogContentText>
                        <TextField type="date" className='field' label="Enddatum" value={this.props.trip && this.props.trip.changeRequestEndDate ? this.props.trip.changeRequestEndDate : ""} onChange={this.props.changeRequestEndDateOnChangeHandler}/>
                    </div> :
                    <div>
                        <DialogContentText>
                            Der aktuelle Fahrtzeitpunkt ist {this.props.trip && this.props.trip.pickUpTime ? this.props.formatDateTime(this.props.trip.pickUpTime) : null}
                            {this.props.trip && this.props.trip.returnTrip ? " für die Hinfahrt und " + this.props.trip.returnTripPickUpTime + " für die Rückfahrt" : null}.
                        </DialogContentText>
                        <h2>Hinfahrt</h2>
                        <TextField type="datetime-local" className='field' label="Zeitpunkt" value={this.props.trip && this.props.trip.changeRequestPickUpTime ? this.props.trip.changeRequestPickUpTime : ""} onChange={this.props.changeRequestPickUpTimeOnChangeHandler}/>
                        <TextField type="datetime-local" className='field' helperText="optional" label="spätester Zeitpunkt" value={this.props.trip && this.props.trip.changeRequestPickUpTimeRange ? this.props.trip.changeRequestPickUpTimeRange : ""} onChange={this.props.changeRequestPickUpTimeRangeOnChangeHandler}/>
                        {this.props.trip && this.props.trip.returnTrip ? <h2>Rückfahrt</h2> : null}
                        {this.props.trip && this.props.trip.returnTrip ? <TextField type="datetime-local" className='field' label="Zeitpunkt" value={this.props.trip && this.props.trip.returnTripChangeRequestPickUpTime ? this.props.trip.returnTripChangeRequestPickUpTime : ""} onChange={this.props.returnTripChangeRequestPickUpTimeOnChangeHandler}/> : null}
                        {this.props.trip && this.props.trip.returnTrip ? <TextField type="datetime-local" className='field' label="spätester Zeitpunkt" value={this.props.trip && this.props.trip.returnTripChangeRequestPickUpTimRange ? this.props.trip.returnTripChangeRequestPickUpTimeRange : ""} onChange={this.props.returnTripChangeRequestPickUpTimeRangeOnChangeHandler}/> : null}
                    </div>}
                </DialogContent>
                <DialogActions>
                    <ToolButton className='button' onClick={() => this.props.saveChangeRequestOnClickHandler(this.props.trip)}>Senden</ToolButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default ChangeRequestDialog;