import React, {Component} from 'react';
import './TripOverview.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@mui/material/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import TripDialog from "../TripDialog/TripDialog";
import api from "../../../../../api";

class TripOverview extends Component {

    state = {
        shownTrips: [],
        searchWord:"",
        searchType:"patientLastName",
        activeCompany:"all",
        activeTrip:null,

        //Dialogs
        showTripDialog:false,
        showDeleteTripDialog:false
    }

    companySearchOnChangeHandler = (company) => {
        this.setState({activeCompany:company});
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchWord:"",searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownTrips = [];
            switch (this.state.searchType) {
                case "patientFirstName":
                    this.props.trips.forEach(trip => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === trip.abbreviation) && trip.patientFirstName && trip.patientFirstName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTrips.push(trip);
                        }
                    });
                    break;
                case "patientLastName":
                    this.props.trips.forEach(trip => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === trip.abbreviation) && trip.patientLastName && trip.patientLastName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTrips.push(trip);
                        }
                    });
                    break;
                case "postCode":
                    this.props.trips.forEach(trip => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === trip.abbreviation) && trip.postCode && trip.postCode.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTrips.push(trip);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownTrips:shownTrips});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    completeFirstTripOnly = (trip) => {
        const url = '/trips/complete/first-only';
        api.post(url,trip)
            .then(response => {
                this.props.addCompletedTripToList(response.data.completedTrip);
                this.props.updateTripInList(response.data.trip);
                this.searchOnClick();
                this.closeTripDialog();
                this.props.showMessage(0,"Die Fahrt wurde erfolgreich abgeschlossen");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    completeTrip = (trip) => {
        const url = '/trips/complete';
        api.post(url,trip)
            .then(response => {
                this.props.removeTripFromList(trip);
                if(response.data.trip) {
                    this.props.addCompletedTripToList(response.data.trip);
                }
                if(response.data.returnTrip) {
                    this.props.addCompletedTripToList(response.data.returnTrip);
                }
                this.searchOnClick();
                this.closeTripDialog();
                this.props.showMessage(0,"Die Fahrt wurde erfolgreich abgeschlossen");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    setTripOpened = (trip) => {
        let url = "";
        if(this.props.company && this.props.company.rights === 1) {
            trip.openedMaster = true;
            url = "/trips/opened-master";
        }else{
            trip.openedCustomer = true;
            url = "/trips/opened-customer";
        }
        api.post(url,trip)
            .then(response => {

            })
    }

    //Dialog actions

    showTripDialog = (trip) => {
        if(this.props.company && ((this.props.company.rights === 1 && !trip.openedMaster) || (this.props.company.rights === 2 && !trip.openedCustomer))) {
            this.setTripOpened(trip);
            this.props.checkForNewTrips(this.props.company,this.props.trips);
        }
        this.setState({activeTrip:trip,showTripDialog:true});
    }

    closeTripDialog = () => {
        this.setState({showTripDialog:false});
    }

    render() {

        return (
            <div className='trip-overview'>
                <ReactTooltip/>

                <TripDialog
                    open={this.state.showTripDialog}
                    close={this.closeTripDialog}
                    showMessage={this.props.showMessage}
                    trip={this.state.activeTrip}
                    countries={this.props.countries}
                    formatDate={this.props.formatDate}
                    completeTrip={this.completeTrip}
                    completeFirstTripOnly={this.completeFirstTripOnly}
                    type={"trip"}

                    //Company
                    company={this.props.company}

                    //Patients
                    patients={this.props.patients}

                    // Stations
                    stations={this.props.stations}
                    station={this.props.station}
                />

                <div className='header-bar'>
                    <h1>Fahrten</h1>
                    {this.props.company && this.props.company.rights === 1 ? <TextField value={this.state.activeCompany} onChange={(e) => this.companySearchOnChangeHandler(e.target.value)} select className='search-select field' label='Unternehmen'>
                        <MenuItem key={0} value={"all"}>Alle</MenuItem>
                        {this.props.companies ? this.props.companies.map((company,index) => (
                            <MenuItem key={index + 1} value={company}>{company.displayName}</MenuItem>
                        )) : []}
                    </TextField> : null}
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-select' label='Suchwort'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'patientFirstName'}>Vorname</MenuItem>
                        <MenuItem key={1} value={'patientLastName'}>Nachname</MenuItem>
                        <MenuItem key={2} value={'postCode'}>Postleitzahl</MenuItem>
                    </TextField>
                    <ToolButton disabled={this.props.loading} main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownTrips.sort((a,b) => a.pickUpTime < b.pickUpTime ? 1 : -1).map((trip,index) => (
                        <ListItem
                            key={index}
                            type="trip"
                            item={trip}
                            showItemDialog={() => this.showTripDialog(trip)}
                            formatDate={this.props.formatDate}
                            company={this.props.company}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default TripOverview;