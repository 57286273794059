import React, {Component} from "react";
import './TripManagement.css';

//Components
import {Route} from "react-router-dom";
import TripOverview from "./TripOverview/TripOverview";
import TripRequestOverview from "./TripRequestOverview/TripRequestOverview";
import CompletedTripOverview from "./CompletedTripOverview/CompletedTripOverview";
import TripSeriesOverview from "./TripSeriesOverview/TripSeriesOverview";

class TripManagement extends Component {

    render() {
        return (
            <div className='trip-management'>
                <Route exact path='/trip-management'>
                    <TripRequestOverview
                        showMessage={this.props.showMessage}
                        formatDate={this.props.formatDate}
                        formatDateTime={this.props.formatDateTime}
                        countries={this.props.countries}
                        loading={this.props.loading}
                        checkForNewTripRequests={this.props.checkForNewTripRequests}

                        //Company
                        company={this.props.company}

                        // Companies
                        companies={this.props.companies}

                        // Trip Requests
                        tripRequests={this.props.tripRequests}
                        addTripRequestToList={this.props.addTripRequestToList}
                        updateTripRequestInList={this.props.updateTripRequestInList}
                        removeTripRequestFromList={this.props.removeTripRequestFromList}
                        generateTripRequestPDF={this.props.generateTripRequestPDF}

                        // Trips
                        trips={this.props.trips}
                        allTrips={this.props.allTrips}
                        addTripToList={this.props.addTripToList}
                        removeTripFromList={this.props.removeTripFromList}

                        // Trip Series
                        tripSeries={this.props.tripSeries}
                        addTripSeriesToList={this.props.addTripSeriesToList}
                        removeTripSeriesFromList={this.props.removeTripSeriesFromList}

                        // Patients
                        patients={this.props.patients}
                        addPatientToList={this.props.addPatientToList}

                        // Stations
                        stations={this.props.stations}
                        station={this.props.station}
                    />
                </Route>
                <Route exact path='/trip-management/trip-overview'>
                    <TripOverview
                        showMessage={this.props.showMessage}
                        formatDate={this.props.formatDate}
                        countries={this.props.countries}
                        loading={this.props.loading}
                        checkForNewTrips={this.props.checkForNewTrips}

                        //Company
                        company={this.props.company}

                        // Companies
                        companies={this.props.companies}

                        // Trips
                        trips={this.props.trips}
                        allTrips={this.props.allTrips}
                        addTripToList={this.props.addTripToList}
                        updateTripInList={this.props.updateTripInList}
                        removeTripFromList={this.props.removeTripFromList}

                        // Completed Trips
                        completedTrips={this.props.completedTrips}
                        addCompletedTripToList={this.props.addCompletedTripToList}
                        removeCompletedTripFromList={this.props.removeCompletedTripFromList}

                        // Patients
                        patients={this.props.patients}

                        // Stations
                        stations={this.props.stations}
                        station={this.props.station}
                    />
                </Route>
                <Route exact path='/trip-management/trip-series-overview'>
                    <TripSeriesOverview
                        showMessage={this.props.showMessage}
                        formatDate={this.props.formatDate}
                        formatDateTime={this.props.formatDateTime}
                        countries={this.props.countries}
                        loading={this.props.loading}
                        checkForNewTripSeries={this.props.checkForNewTripSeries}

                        //Company
                        company={this.props.company}

                        // Companies
                        companies={this.props.companies}

                        // Trip Series
                        tripSeries={this.props.tripSeries}
                        addTripSeriesToList={this.props.addTripSeriesToList}
                        updateTripSeriesInList={this.props.updateTripSeriesInList}
                        removeTripSeriesFromList={this.props.removeTripSeriesFromList}

                        // Completed Trips
                        completedTrips={this.props.completedTrips}
                        addCompletedTripToList={this.props.addCompletedTripToList}
                        removeCompletedTripFromList={this.props.removeCompletedTripFromList}

                        // Patients
                        patients={this.props.patients}

                        // Stations
                        stations={this.props.stations}
                        station={this.props.station}
                    />
                </Route>
                <Route exact path='/trip-management/completed-trip-overview'>
                    <CompletedTripOverview
                        showMessage={this.props.showMessage}
                        formatDate={this.props.formatDate}
                        countries={this.props.countries}
                        loading={this.props.loading}
                        checkForNewCompletedTrips={this.props.checkForNewCompletedTrips}

                        //Company
                        company={this.props.company}

                        // Companies
                        companies={this.props.companies}

                        // Completed Trips
                        completedTrips={this.props.completedTrips}
                        addCompletedTripToList={this.props.addCompletedTripToList}
                        removeCompletedTripFromList={this.props.removeCompletedTripFromList}

                        // Patients
                        patients={this.props.patients}

                        // Stations
                        stations={this.props.stations}
                        station={this.props.station}
                    />
                </Route>
            </div>
        )
    }
}

export default TripManagement;