import React, {Component} from 'react';
import './MasterDataItem.css';

//Components
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';

class MasterDataItem extends Component {
    render() {
        return (
            <Paper elevation={3} className='master-data-item'>
                <DeleteIcon onClick={this.props.deleteItem} className='icon delete-icon'/>
                <EditIcon onClick={() => this.props.showItemDialog(false,this.props.item)} className='icon edit-icon'/>
                <p className='info-small'>
                    {this.props.type === "patient" && this.props.item && this.props.item.birthdate ? this.props.formatDate(this.props.item.birthdate) : ""}
                </p>
                <h3 className='info-big'>
                    {this.props.type === "patient" && this.props.item && this.props.item.firstName && this.props.item.lastName ? this.props.item.firstName + " " + this.props.item.lastName : ""}
                    {this.props.type === "station" && this.props.item && this.props.item.name ? this.props.item.name : ""}
                </h3>
            </Paper>
        )
    }
}

export default MasterDataItem;