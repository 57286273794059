import React, {Component} from "react";
import './CompanyReport.css';

//Components
import Paper from '@mui/material/Paper';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactTooltip from "react-tooltip";

class CompanyReport extends Component {

    state = {
        folded:true
    }

    toggleContent = () => {
        this.setState({folded:!this.state.folded});
    }

    render() {

        let detailedInformation = this.state.folded ?
            null :
            <div className='detailed-information'>
                <table>
                    <tr>
                        <th><h3>Patient</h3></th>
                        <th><h3>Datum</h3></th>
                    </tr>
                    {this.props.companyReport.completedTrips.sort((a,b) => a.pickUpTime < b.pickUpTime ? 1 : -1).map(completedTrip => (
                        <tr>
                            <td data-tip={completedTrip.patientFirstName + " " + completedTrip.patientLastName + " (" + this.props.formatDate(completedTrip.patientBirthdate) + ")"}>{this.props.formatString(completedTrip.patientFirstName + " " + completedTrip.patientLastName + " (" + this.props.formatDate(completedTrip.patientBirthdate) + ")", 30)}</td>
                            <td>{this.props.formatDate(completedTrip.pickUpTime)}</td>
                        </tr>
                    ))}
                </table>
            </div>

        return (
            <Paper elevation={3} className='company-report'>
                <ReactTooltip/>
                <div className='top-field'>
                    <ul>
                        <li>
                            <h3 className='field'>{this.props.companyReport && this.props.companyReport.company && this.props.companyReport.company.displayName ? this.props.formatString(this.props.companyReport.company.displayName,25) : ""}</h3>
                        </li>
                        <li>
                            <h3 className='field'>{'Abgeschlossene Fahrten: ' + this.props.companyReport.completedTrips.length}</h3>
                        </li>
                        <li className='icon-holder'>
                            {this.state.folded ?
                                <KeyboardArrowRightIcon onClick={this.toggleContent} className='arrow-icon'/> :
                                <KeyboardArrowDownIcon onClick={this.toggleContent} className='arrow-icon' />}
                        </li>
                    </ul>
                </div>
                {detailedInformation}
            </Paper>
        )
    }
}

export default CompanyReport;