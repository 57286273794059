import React, {Component} from "react";
import './Tool.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from "../../api";

//Components
import Toolbar from "./Toolbar/Toolbar";
import SidePanel from "./SidePanel/SidePanel";
import BottomPanel from "./BottomPanel/BottomPanel";
import Actions from "./Actions/Actions";
import ActionsMenu from "./ActionsMenu/ActionsMenu";
import UserMenu from "./UserMenu/UserMenu";
import jsPDF from "jspdf";
import './../../fonts/OpenSans/OpenSans-Regular.ttf';
import './../../fonts/OpenSans/OpenSans-Bold.ttf';
import Chat from "./../../reusable/Chat/Chat";

class Tool extends Component {

    state = {
        user:null,
        users:[],
        crossUsers:[],
        company:null,
        patients:[],
        stations:[],
        allTripRequests:[],
        tripRequests:[],
        allTrips:[],
        trips:[],
        allTripSeries:[],
        tripSeries:[],
        allCompletedTrips:[],
        completedTrips:[],
        companies:[],
        loading:false,
        station:null,
        containsNewTripRequests:false,
        containsNewTrips:false,
        containsNewTripSeries:false,
        containsNewCompletedTrips:false,
        chatData:null,
        newMessage:false
    };

    //Load data

    componentDidMount() {
        this.loadCompanyData();
        setInterval(() => this.loadCompanyData(),180000);// 3 minutes
    }

    loadCompanyData = () => {
        const url = '/company-data';
        api.get(url)
            .then(response => {
                let allTripRequests = response.data.tripRequests;
                let tripRequests = [];
                let allTrips = response.data.trips
                let trips = [];
                let allTripSeries = response.data.tripSeries;
                let tripSeries = [];
                let allCompletedTrips = response.data.completedTrips;
                let completedTrips = [];
                if(this.state.station && this.state.station.name) {
                    for(let i = 0; i < allTripRequests.length; i++) {
                        if(allTripRequests[i].stationName && allTripRequests[i].stationName === this.state.station.name) {
                            tripRequests.unshift(allTripRequests[i]);
                        }
                    }
                    for(let i = 0; i < allTrips.length; i++) {
                        if(allTrips[i].stationName && allTrips[i].stationName === this.state.station.name) {
                            trips.unshift(allTrips[i]);
                        }
                    }
                    for(let i = 0; i < allTripSeries.length; i++) {
                        if(allTripSeries[i].stationName && allTripSeries[i].stationName === this.state.station.name) {
                            tripSeries.unshift(allTripSeries[i]);
                        }
                    }
                    for(let i = 0; i < allCompletedTrips.length; i++) {
                        if(allCompletedTrips[i].stationName && allCompletedTrips[i].stationName === this.state.station.name) {
                            completedTrips.unshift(allCompletedTrips[i]);
                        }
                    }
                }else{
                    tripRequests = allTripRequests;
                    trips = allTrips;
                    tripSeries = allTripSeries;
                    completedTrips = allCompletedTrips;
                }
                let stations = response.data.stations;
                this.setState({
                    user:response.data.applicationUser,
                    company:response.data.company,
                    companies:response.data.companies,
                    tripRequests:tripRequests,
                    allTripRequests:allTripRequests,
                    trips:trips,
                    allTrips:allTrips,
                    tripSeries:tripSeries,
                    allTripSeries:allTripSeries,
                    completedTrips:completedTrips,
                    allCompletedTrips:allCompletedTrips,
                    patients:response.data.patients,
                    stations:response.data.stations,
                    users:response.data.users,
                    crossUsers:response.data.crossUsers,
                    chatData:response.data.chatData
                },() => {
                    this.setState({newMessage:!this.isSeenInChatData(response.data.chatData)});
                });
                this.checkForNewTripRequests(response.data.company,tripRequests);
                this.checkForNewTrips(response.data.company,trips);
                this.checkForNewTripSeries(response.data.company,tripSeries);
                this.checkForNewCompletedTrips(response.data.company,completedTrips);
                this.formatData();
            })
            .catch(error => {
                this.props.logout();
            });
    }

    isSeenInUser = (messages) => {
        for(let i = messages.length - 1; i >= 0; i--) {
            if(this.state.company.abbreviation === messages[i].receiverAbbreviation && this.state.user.username === messages[i].receiverName) {
                return messages[i].seen;
            }
        }
        return true;
    }

    isSeenInCompany = (company) => {
        let seen = true;
        for(let i = 0; i < company.chatUsers.length; i++) {
            if(!this.isSeenInUser(company.chatUsers[i].messages)) {
                seen = false;
                break;
            }
        }
        return seen;
    }

    isSeenInChatData = (chatData) => {
        let seen = true;
        for(let i = 0; i < chatData.chatCompanies.length; i++) {
            if(!this.isSeenInCompany(chatData.chatCompanies[i])) {
                seen = false;
                break;
            }
        }
        return seen;
    }

    loadUser = () => {
        const url = '/users/current';
        api.get(url)
            .then(response => {
                this.setState({user:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadUsers = () => {
        const url = '/users';
        api.get(url)
            .then(response => {
                this.setState({users:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCompany = () => {
        const url = '/company';
        api.get(url)
            .then(response => {
                switch (response.data.rights) {
                    case 1:
                        this.loadCompanies();
                        break;
                    case 2:
                        break;
                    default:
                        break;
                }
                this.setState({company:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    loadCompanies = () => {
        const url = '/company/all';
        api.get(url)
            .then(response => {
                this.setState({companies:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    // Patients

    addPatientToList = (patient) => {
        let patients = this.state.patients;
        patients.push(patient);
        this.setState({patients:patients});
    }

    updatePatientInList = (patient) => {
        let patients = this.state.patients;
        for(let i = 0; i < patients.length; i++) {
            if(patients[i].id === patient.id) {
                patients[i] = patient;
                break;
            }
        }
        this.setState({patients:patients});
    }

    removePatientFromList = (patient) => {
        let patients = [...this.state.patients];
        for(let i = 0; i < patients.length; i++) {
            if (patients[i].id === patient.id) {
                patients.splice(i,1)
                this.setState({patients:patients});
                return;
            }
        }
    }

    // Stations

    addStationToList = (station) => {
        let stations = this.state.stations;
        stations.push(station);
        this.setState({stations:stations});
    }

    updateStationInList = (station) => {
        let stations = this.state.stations;
        for(let i = 0; i < stations.length; i++) {
            if(stations[i].id === station.id) {
                stations[i] = station;
                break;
            }
        }
        this.setState({stations:stations});
    }

    removeStationFromList = (station) => {
        let stations = [...this.state.stations];
        for(let i = 0; i < stations.length; i++) {
            if (stations[i].id === station.id) {
                stations.splice(i,1)
                this.setState({stations:stations});
                return;
            }
        }
    }

    // Trip Requests

    addTripRequestToList = (tripRequest) => {
        let tripRequests = this.state.tripRequests;
        tripRequests.push(tripRequest);
        this.setState({tripRequests:tripRequests});
        this.checkForNewTripRequests(this.state.company,tripRequests);
    }

    updateTripRequestInList = (tripRequest) => {
        let tripRequests = this.state.tripRequests;
        for(let i = 0; i < tripRequests.length; i++) {
            if(tripRequests[i].id === tripRequest.id) {
                tripRequests[i] = tripRequest;
                break;
            }
        }
        this.setState({tripRequests:tripRequests});
    }

    removeTripRequestFromList = (tripRequest) => {
        let tripRequests = [...this.state.tripRequests];
        for(let i = 0; i < tripRequests.length; i++) {
            if (tripRequests[i].id === tripRequest.id) {
                tripRequests.splice(i,1);
                break;
            }
        }
        this.setState({tripRequests:tripRequests});
    }

    // Trips

    loadTrips = () => {
        const url = '/trips';
        api.get(url)
            .then(response => {
                console.log(response.data)
                this.setState({trips:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    addTripToList = (trip) => {
        let trips = this.state.trips;
        trips.push(trip);
        this.setState({trips:trips});
        this.checkForNewTrips(this.state.company,trips);
    }

    updateTripInList = (trip) => {
        let trips = this.state.trips;
        for(let i = 0; i < trips.length; i++) {
            if(trips[i].id === trip.id) {
                trips[i] = trip;
                break;
            }
        }
        this.setState({trips:trips});
    }

    removeTripFromList = (trip) => {
        let trips = [...this.state.trips];
        for(let i = 0; i < trips.length; i++) {
            if (trips[i].id === trip.id) {
                trips.splice(i,1)
                break;
            }
        }
        this.setState({trips:trips});
    }

    // Trips

    addTripSeriesToList = (newTripSeries) => {
        let tripSeries = this.state.tripSeries;
        tripSeries.push(newTripSeries);
        this.setState({tripSeries:tripSeries});
        this.checkForNewTripSeries(this.state.company,tripSeries);
    }

    updateTripSeriesInList = (tripSerie) => {
        let tripSeries = this.state.tripSeries;
        for(let i = 0; i < tripSeries.length; i++) {
            if(tripSeries[i].id === tripSerie.id) {
                tripSeries[i] = tripSerie;
                break;
            }
        }
        this.setState({tripSeries:tripSeries});
    }

    removeTripSeriesFromList = (oldTripSeries) => {
        let tripSeries = [...this.state.tripSeries];
        for(let i = 0; i < tripSeries.length; i++) {
            if (tripSeries[i].id === oldTripSeries.id) {
                tripSeries.splice(i,1)
                break;
            }
        }
        this.setState({tripSeries:tripSeries});
    }

    // Completed Trips

    loadCompletedTrips = () => {
        const url = '/completed-trips';
        api.get(url)
            .then(response => {
                this.setState({completedTrips:response.data});
            })
            .catch(error => {
                this.props.logout();
            });
    }

    addCompletedTripToList = (completedTrip) => {
        let completedTrips = this.state.completedTrips;
        completedTrips.push(completedTrip);
        this.setState({completedTrips:completedTrips});
        this.checkForNewCompletedTrips(this.state.company,completedTrips);
    }

    removeCompletedTripFromList = (completedTrip) => {
        let completedTrips = [...this.state.completedTrips];
        for(let i = 0; i < completedTrips.length; i++) {
            if (completedTrips[i].id === completedTrip.id) {
                completedTrips.splice(i,1);
                break;
            }
        }
        this.setState({completedTrips:completedTrips});
    }

    // Other

    addUserToList = (user) => {
        let users = this.state.users;
        users.push(user);
        this.setState({users:users});
    };

    removeUserFromList = (user) => {
        let users = [...this.state.users];
        for(let i = 0; i < users.length; i++) {
            if (users[i].id === user.id) {
                users.splice(i,1)
                this.setState({users:users});
                return;
            }
        }
    }

    updateUserInList = (user) => {
        let users = this.state.users;
        for(let i = 0; i < users.length; i++) {
            if(users[i].id === user.id) {
                users[i] = user;
                break;
            }
        }
        this.setState({users:users});
    }

    // Company

    updateCompanyInformationInState = (companyInformation) => {
        let company = this.state.company;
        company.companyInformation = companyInformation;
        this.setState({company:company});
    }

    round = (value, decimals) => {
        return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
    }

    formatString = (string,maxLength) => {
        let substring = string;
        if(string.length > maxLength) {
            substring = string.substring(0,maxLength) + "..."
        }
        return substring;
    }

    formatDate = (date) => {
        if(date) {
            return date.substring(8, 10) + "." + date.substring(5,7) + "." + date.substring(0, 4);
        }
    }

    formatTime = (dateTime) => {
        if(dateTime) {
            return dateTime.substring(11,16) + " Uhr";
        }
    }

    formatDateTime = (dateTime) => {
        if(dateTime) {
            return dateTime.substring(8, 10) + "." + dateTime.substring(5,7) + "." + dateTime.substring(0, 4) + " " + dateTime.substring(11,16) + " Uhr";
        }
    }

    setLoading = (loading) => {
        this.setState({loading:loading});
    }

    formatData = () => {
        let completedTrips = this.state.completedTrips;
        let formattedData = [];
        let today = new Date();
        for(let i = 0; i < 30; i++) {
            today.setDate(today.getDate()-1);
            let sum = 0;
            for(let j = 0; j < completedTrips.length; j++) {
                if(completedTrips[j].pickUpTime.substring(0,10) === today.toISOString().substring(0,10)) {
                    sum++;
                }
            }
            let todayData = {
                "Datum":this.formatDate(today.toISOString()),
                "Abgeschlossene Fahrten":sum
            }
            formattedData.unshift(todayData);
        }
        this.setState({formattedData:formattedData});
    }

    stationOnChangeHandler = (e) => {
        let station = e.target.value;
        let tripRequests = [];
        let trips = [];
        let tripSeries = [];
        let completedTrips = [];
        if(station && station.name) {
            for(let i = 0; i < this.state.allTripRequests.length; i++) {
                if(
                    (this.state.allTripRequests[i].stationNameFrom && this.state.allTripRequests[i].stationNameFrom === station.name) ||
                    (this.state.allTripRequests[i].stationNameTo && this.state.allTripRequests[i].stationNameTo === station.name) ||
                    (this.state.allTripRequests[i].returnTripStationNameFrom && this.state.allTripRequests[i].returnTripStationNameFrom === station.name) ||
                    (this.state.allTripRequests[i].returnTripStationNameTo && this.state.allTripRequests[i].returnTripStationNameTo === station.name)
                ) {
                    tripRequests.unshift(this.state.allTripRequests[i]);
                }
            }
            for(let i = 0; i < this.state.allTrips.length; i++) {
                if(
                    (this.state.allTrips[i].stationNameFrom && this.state.allTrips[i].stationNameFrom === station.name) ||
                    (this.state.allTrips[i].stationNameTo && this.state.allTrips[i].stationNameTo === station.name) ||
                    (this.state.allTrips[i].returnTripStationNameFrom && this.state.allTrips[i].returnTripStationNameFrom === station.name) ||
                    (this.state.allTrips[i].returnTripStationNameTo && this.state.allTrips[i].returnTripStationNameTo === station.name)
                ) {
                    trips.unshift(this.state.allTrips[i]);
                }
            }
            for(let i = 0; i < this.state.allTripSeries.length; i++) {
                if(
                    (this.state.allTripSeries[i].stationNameFrom && this.state.allTripSeries[i].stationNameFrom === station.name) ||
                    (this.state.allTripSeries[i].stationNameTo && this.state.allTripSeries[i].stationNameTo === station.name) ||
                    (this.state.allTripSeries[i].returnTripStationNameFrom && this.state.allTripSeries[i].returnTripStationNameFrom === station.name) ||
                    (this.state.allTripSeries[i].returnTripStationNameTo && this.state.allTripSeries[i].returnTripStationNameTo === station.name)

                ) {
                    tripSeries.unshift(this.state.allTripSeries[i]);
                }
            }
            for(let i = 0; i < this.state.allCompletedTrips.length; i++) {
                if(
                    (this.state.allCompletedTrips[i].stationNameFrom && this.state.allCompletedTrips[i].stationNameFrom === station.name) ||
                    (this.state.allCompletedTrips[i].stationNameTo && this.state.allCompletedTrips[i].stationNameTo === station.name) ||
                    (this.state.allCompletedTrips[i].returnTripStationNameFrom && this.state.allCompletedTrips[i].returnTripStationNameFrom === station.name) ||
                    (this.state.allCompletedTrips[i].returnTripStationNameTo && this.state.allCompletedTrips[i].returnTripStationNameTo === station.name)
                ) {
                    completedTrips.unshift(this.state.allCompletedTrips[i]);
                }
            }
        }else{
            tripRequests = this.state.allTripRequests;
            trips = this.state.allTrips;
            tripSeries = this.state.allTripSeries;
            completedTrips = this.state.allCompletedTrips;
        }
        this.setState({tripRequests:tripRequests,trips:trips,tripSeries:tripSeries,completedTrips:completedTrips,station:station});
    }

    generateTripRequestPDF = (tripRequest) => {
        let pdf = new jsPDF('p','px');
        //format: ~445 x 630
        pdf.addFont('OpenSans-Regular.ttf', 'OpenSans-Regular', 'normal');
        pdf.setFont('OpenSans-Regular');
        if(this.state.company.companyInformation.logo) {
            pdf.addImage(this.state.company.companyInformation.logo, 185, 20, 75, 75);
        }
        pdf.setFontSize(16);
        pdf.text('Fahrtanfrage',40,120);
        pdf.setFontSize(12);
        pdf.text("von " + tripRequest.companyName,40,135);
        pdf.setFontSize(10);
        pdf.text("Patient",40,155);
        pdf.text("Vorname: " + tripRequest.patientFirstName,40,165);
        pdf.text("Nachname: " + tripRequest.patientLastName,40,175);
        pdf.text("Geburtsdatum: " + this.formatDate(tripRequest.patientBirthdate),40,185);
        pdf.text("Station: " + (tripRequest.stationName ? tripRequest.stationName : "-"),40,195);

        pdf.setFontSize(12);
        pdf.text(tripRequest.returnTrip ? "Hinfahrt" : "Einzelfahrt",40,215);

        pdf.setFontSize(10);
        pdf.text("Abholung",40,235);
        pdf.text("Zeitpunkt: " + (tripRequest.tripSeries ? "- (Serienfahrt)" : this.formatDateTime(tripRequest.pickUpTime) + (tripRequest.pickUpTimeRange ? " - " + this.formatTime(tripRequest.pickUpTimeRange) : "")),40,245);
        pdf.text("Ortsbezeichnung: " + tripRequest.locationNameFrom,40,255);
        pdf.text("Straßenname: " + tripRequest.streetNameFrom,40,265);
        pdf.text("Hausnummer: " + tripRequest.streetNrFrom,40,275);
        pdf.text("Adresszusatz: " + (tripRequest.addressAdditionFrom ? tripRequest.addressAdditionFrom : "-"),40,285);
        pdf.text("Postleitzahl: " + tripRequest.postCodeFrom,40,295);
        pdf.text("Stadt: " + tripRequest.cityFrom,40,305);
        pdf.text("Land: " + tripRequest.countryFrom,40,315);

        pdf.text("Ankunft",40,335);
        pdf.text("Ortsbezeichnung: " + tripRequest.locationNameTo,40,345);
        pdf.text("Straßenname: " + tripRequest.streetNameTo,40,355);
        pdf.text("Hausnummer: " + tripRequest.streetNrTo,40,365);
        pdf.text("Adresszusatz: " + (tripRequest.addressAdditionTo ? tripRequest.addressAdditionTo : "-"),40,375);
        pdf.text("Postleitzahl: " + tripRequest.postCodeTo,40,385);
        pdf.text("Stadt: " + tripRequest.cityTo,40,395);
        pdf.text("Land: " + tripRequest.countryTo,40,405);

        pdf.text("Sonstiges",40,425);
        pdf.text("Begleitperson: " + (tripRequest.companion ? "Ja" : "Nein"),40,435);
        pdf.text("Infektion: " + (tripRequest.infection ? "Ja" : "Nein"),40,445);
        let costUnit = "-";
        switch (tripRequest.costUnit) {
            case 1:
                costUnit = "Patient"
                break;
            case 2:
                costUnit = tripRequest.healthInsurance;
                break;
        }
        pdf.text("Kostenträger: " + costUnit,40,455);
        let transportKind = "-";
        switch (tripRequest.transportKind) {
            case 1:
                transportKind = "Tragestuhl"
                break;
            case 2:
                transportKind = "liegend";
                break;
            case 3:
                transportKind = "gehfähig";
                break;
        }
        pdf.text("Transportart: " + transportKind,40,465);
        let tripType = "-";
        switch (tripRequest.tripType) {
            case 1:
                tripType = "Einweisung"
                break;
            case 2:
                tripType = "Entlassung";
                break;
            case 3:
                tripType = "Sonstiges";
                break;
        }
        pdf.text("Fahrttyp: " + tripType,40,475);
        pdf.text("Kommentar: " + (tripRequest.comment ? tripRequest.comment : "-"),40,485);

        if(tripRequest.tripSeries) {
            pdf.text("Wiederholung",40,505);
            pdf.text("Montags: " + (tripRequest.monday ? tripRequest.mondayTime + " Uhr" : "-"),40,515);
            pdf.text("Dienstags: " + (tripRequest.tuesday ? tripRequest.tuesdayTime + " Uhr" : "-"),40,525);
            pdf.text("Mittwochs: " + (tripRequest.wednesday ? tripRequest.wednesdayTime + " Uhr" : "-"),40,535);
            pdf.text("Donnerstags: " + (tripRequest.thursday ? tripRequest.thursdayTime + " Uhr" : "-"),40,545);
            pdf.text("Freitags: " + (tripRequest.friday ? tripRequest.fridayTime + " Uhr" : "-"),40,555);
            pdf.text("Samstags: " + (tripRequest.saturday ? tripRequest.saturdayTime + " Uhr" : "-"),40,565);
            pdf.text("Sonntags: " + (tripRequest.sunday ? tripRequest.sundayTime + " Uhr" : "-"),40,575);
        }

        if(tripRequest.returnTrip) {
            pdf.setFontSize(12);
            pdf.text("Rückfahrt",240,215);

            pdf.setFontSize(10);
            pdf.text("Abholung",240,235);
            pdf.text("Zeitpunkt: " + (tripRequest.tripSeries ? "- (Serienfahrt)" : this.formatDateTime(tripRequest.returnTripPickUpTime) + (tripRequest.returnTripPickUpTimeRange ? " - " + this.formatTime(tripRequest.returnTripPickUpTimeRange) : "")),240,245);
            pdf.text("Ortsbezeichnung: " + tripRequest.returnTripLocationNameFrom,240,255);
            pdf.text("Straßenname: " + tripRequest.returnTripStreetNameFrom,240,265);
            pdf.text("Hausnummer: " + tripRequest.returnTripStreetNrFrom,240,275);
            pdf.text("Adresszusatz: " + (tripRequest.returnTripAddressAdditionFrom ? tripRequest.returnTripAddressAdditionFrom : "-"),240,285);
            pdf.text("Postleitzahl: " + tripRequest.returnTripPostCodeFrom,240,295);
            pdf.text("Stadt: " + tripRequest.returnTripCityFrom,240,305);
            pdf.text("Land: " + tripRequest.returnTripCountryFrom,240,315);

            pdf.text("Ankunft",240,335);
            pdf.text("Ortsbezeichnung: " + tripRequest.returnTripLocationNameTo,240,345);
            pdf.text("Straßenname: " + tripRequest.returnTripStreetNameTo,240,355);
            pdf.text("Hausnummer: " + tripRequest.returnTripStreetNrTo,240,365);
            pdf.text("Adresszusatz: " + (tripRequest.returnTripAddressAdditionTo ? tripRequest.returnTripAddressAdditionTo : "-"),240,375);
            pdf.text("Postleitzahl: " + tripRequest.returnTripPostCodeTo,240,385);
            pdf.text("Stadt: " + tripRequest.returnTripCityTo,240,395);
            pdf.text("Land: " + tripRequest.returnTripCountryTo,240,405);

            pdf.text("Sonstiges",240,425);
            pdf.text("Begleitperson: " + (tripRequest.returnTripCompanion ? "Ja" : "Nein"),240,435);
            pdf.text("Infektion: " + (tripRequest.returnTripInfection ? "Ja" : "Nein"),240,445);
            let returnTripCostUnit = "-";
            switch (tripRequest.returnTripCostUnit) {
                case 1:
                    returnTripCostUnit = "Patient"
                    break;
                case 2:
                    returnTripCostUnit = tripRequest.returnTripHealthInsurance;
                    break;
            }
            pdf.text("Kostenträger: " + costUnit,240,455);
            let returnTripTransportKind = "-";
            switch (tripRequest.returnTripTransportKind) {
                case 1:
                    returnTripTransportKind = "Tragestuhl"
                    break;
                case 2:
                    returnTripTransportKind = "liegend";
                    break;
                case 3:
                    returnTripTransportKind = "gehfähig";
                    break;
            }
            pdf.text("Transportart: " + returnTripTransportKind,240,465);
            let returnTripTripType = "-";
            switch (tripRequest.returnTripTripType) {
                case 1:
                    returnTripTripType = "Einweisung"
                    break;
                case 2:
                    returnTripTripType = "Entlassung";
                    break;
                case 3:
                    returnTripTripType = "Sonstiges";
                    break;
            }
            pdf.text("Fahrttyp: " + returnTripTripType,240,475);
            pdf.text("Kommentar: " + (tripRequest.returnTripComment ? tripRequest.returnTripComment : "-"),240,485);

            if(tripRequest.tripSeries) {
                pdf.text("Wiederholung",240,505);
                pdf.text("Startdatum: " + (tripRequest.startDate ? tripRequest.startDate : "-"),240,515);
                pdf.text("Enddatum: " + (tripRequest.startDate ? tripRequest.endDate : "-"),240,525);
                pdf.text("Montags: " + (tripRequest.returnTripMonday ? tripRequest.returnTripMondayTime + " Uhr" : "-"),240,535);
                pdf.text("Dienstags: " + (tripRequest.returnTripTuesday ? tripRequest.returnTripTuesdayTime + " Uhr" : "-"),240,545);
                pdf.text("Mittwochs: " + (tripRequest.returnTripWednesday ? tripRequest.returnTripWednesdayTime + " Uhr" : "-"),240,555);
                pdf.text("Donnerstags: " + (tripRequest.returnTripThursday ? tripRequest.returnTripThursdayTime + " Uhr" : "-"),240,565);
                pdf.text("Freitags: " + (tripRequest.returnTripFriday ? tripRequest.returnTripFridayTime + " Uhr" : "-"),240,575);
                pdf.text("Samstags: " + (tripRequest.returnTripSaturday ? tripRequest.returnTripSaturdayTime + " Uhr" : "-"),240,585);
                pdf.text("Sonntags: " + (tripRequest.returnTripSunday ? tripRequest.returnTripSundayTime + " Uhr" : "-"),240,595);
            }
        }

        pdf.save('fahrtanfrage_' + tripRequest.patientFirstName + tripRequest.patientLastName + '.pdf');
    }

    checkForNewTripRequests = (company,tripRequests) => {
        let containsNewTripRequests = false;
        if(company && company.rights === 1) {
            for(let i = 0; i < tripRequests.length; i++) {
                if(!tripRequests[i].openedMaster) {
                    containsNewTripRequests = true;
                    break;
                }
            }
        }else if(company && company.rights === 2) {
            for(let i = 0; i < tripRequests.length; i++) {
                if(!tripRequests[i].openedCustomer) {
                    containsNewTripRequests = true;
                    break;
                }
            }
        }
        this.setState({containsNewTripRequests:containsNewTripRequests});
    }

    checkForNewTrips = (company,trips) => {
        let containsNewTrips = false;
        if(company && company.rights === 1) {
            for(let i = 0; i < trips.length; i++) {
                if(!trips[i].openedMaster) {
                    containsNewTrips = true;
                    break;
                }
            }
        }else if(company && company.rights === 2) {
            for(let i = 0; i < trips.length; i++) {
                if(!trips[i].openedCustomer) {
                    containsNewTrips = true;
                    break;
                }
            }
        }
        this.setState({containsNewTrips:containsNewTrips});
    }

    checkForNewTripSeries = (company,tripSeries) => {
        let containsNewTripSeries = false;
        if(company && company.rights === 1) {
            for(let i = 0; i < tripSeries.length; i++) {
                if(!tripSeries[i].openedMaster) {
                    containsNewTripSeries = true;
                    break;
                }
            }
        }else if(company && company.rights === 2) {
            for(let i = 0; i < tripSeries.length; i++) {
                if(!tripSeries[i].openedCustomer) {
                    containsNewTripSeries = true;
                    break;
                }
            }
        }
        this.setState({containsNewTripSeries:containsNewTripSeries});
    }

    checkForNewCompletedTrips = (company,completedTrips) => {
        let containsNewCompletedTrips = false;
        if(company && company.rights === 1) {
            for(let i = 0; i < completedTrips.length; i++) {
                if(!completedTrips[i].openedMaster) {
                    containsNewCompletedTrips = true;
                    break;
                }
            }
        }else if(company && company.rights === 2) {
            for(let i = 0; i < completedTrips.length; i++) {
                if(!completedTrips[i].openedCustomer) {
                    containsNewCompletedTrips = true;
                    break;
                }
            }
        }
        this.setState({containsNewCompletedTrips:containsNewCompletedTrips});
    }

    setNewMessage = (newMessage) => {
        this.setState({newMessage:newMessage});
    }

    render() {
        return (
            <div className='tool'>

                <Toolbar
                    user={this.state.user}
                    company={this.state.company}
                />
                <div className='content'>
                    <UserMenu
                        // Data
                        user={this.state.user}
                        logout={this.props.logout}
                        company={this.state.company}

                        // Stations
                        stations={this.state.stations}
                        stationOnChangeHandler={this.stationOnChangeHandler}
                    />
                    <ActionsMenu
                        tripRequests={this.state.tripRequests}
                        trips={this.state.trips}
                        tripSeries={this.state.tripSeries}
                        completedTrips={this.state.completedTrips}
                        company={this.state.company}
                        containsNewTripRequests={this.state.containsNewTripRequests}
                        containsNewTrips={this.state.containsNewTrips}
                        containsNewTripSeries={this.state.containsNewTripSeries}
                        containsNewCompletedTrips={this.state.containsNewCompletedTrips}
                    />
                    <Actions
                        // Common
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        round={this.round}
                        formatDate={this.formatDate}
                        formatDateTime={this.formatDateTime}
                        formatString={this.formatString}
                        loading={this.state.loading}
                        formattedData={this.state.formattedData}

                        checkForNewTripRequests={this.checkForNewTripRequests}
                        checkForNewTrips={this.checkForNewTrips}
                        checkForNewTripSeries={this.checkForNewTripSeries}
                        checkForNewCompletedTrips={this.checkForNewCompletedTrips}

                        //Company
                        company={this.state.company}
                        companies={this.state.companies}
                        updateCompanyInformationInState={this.updateCompanyInformationInState}

                        // User
                        user={this.state.user}
                        users={this.state.users}
                        loadUser={this.loadUser}
                        logout={this.props.logout}
                        updateUserInList={this.updateUserInList}
                        addUserToList={this.addUserToList}
                        removeUserFromList={this.removeUserFromList}

                        // Patients
                        patients={this.state.patients}
                        addPatientToList={this.addPatientToList}
                        updatePatientInList={this.updatePatientInList}
                        removePatientFromList={this.removePatientFromList}

                        // Stations
                        stations={this.state.stations}
                        addStationToList={this.addStationToList}
                        updateStationInList={this.updateStationInList}
                        removeStationFromList={this.removeStationFromList}
                        station={this.state.station}

                        // Trip Requests
                        tripRequests={this.state.tripRequests}
                        addTripRequestToList={this.addTripRequestToList}
                        updateTripRequestInList={this.updateTripRequestInList}
                        removeTripRequestFromList={this.removeTripRequestFromList}
                        generateTripRequestPDF={this.generateTripRequestPDF}

                        // Trips
                        trips={this.state.trips}
                        allTrips={this.state.allTrips}
                        addTripToList={this.addTripToList}
                        updateTripInList={this.updateTripInList}
                        removeTripFromList={this.removeTripFromList}

                        // Trip Series
                        tripSeries={this.state.tripSeries}
                        addTripSeriesToList={this.addTripSeriesToList}
                        updateTripSeriesInList={this.updateTripSeriesInList}
                        removeTripSeriesFromList={this.removeTripSeriesFromList}

                        // Completed Trips
                        completedTrips={this.state.completedTrips}
                        addCompletedTripToList={this.addCompletedTripToList}
                        removeCompletedTripFromList={this.removeCompletedTripFromList}
                    />
                    <BottomPanel
                        //Common
                        showMessage={this.props.showMessage}
                    />
                    <SidePanel
                        // Common
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        round={this.round}
                        formatDate={this.formatDate}
                        formatDateTime={this.formatDateTime}
                        formatString={this.formatString}
                        loading={this.state.loading}
                        company={this.state.company}

                        // Trips
                        trips={this.state.trips}

                        // Trip Series
                        tripSeries={this.state.tripSeries}
                    />
                    <Chat
                        user={this.state.user}
                        chatData={this.state.chatData}
                        logout={this.props.logout}
                        showMessage={this.props.showMessage}
                        company={this.state.company}
                        formatDateTime={this.formatDateTime}
                        newMessage={this.state.newMessage}
                        setNewMessage={this.setNewMessage}
                    />
                </div>
            </div>
        )
    }
}

export default Tool;