import React, {Component} from "react";
import './MasterData.css';
import {Route} from 'react-router-dom';

//Components
import MasterDataContent from "./MasterDataContent/MasterDataContent";

class MasterData extends Component {
    render() {
        return (
            <div className='master-data'>
                <Route exact path='/master-data'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        formatDate={this.props.formatDate}
                        items={this.props.patients}
                        addItemToList={this.props.addPatientToList}
                        updateItemInList={this.props.updatePatientInList}
                        removeItemFromList={this.props.removePatientFromList}
                        type="patient"
                    />
                </Route>
                <Route exact path='/master-data/stations'>
                    <MasterDataContent
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        formatDate={this.props.formatDate}
                        items={this.props.stations}
                        addItemToList={this.props.addStationToList}
                        updateItemInList={this.props.updateStationInList}
                        removeItemFromList={this.props.removeStationFromList}
                        type="station"
                    />
                </Route>
            </div>
        )
    }
}

export default MasterData;