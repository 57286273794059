import React, {Component} from "react";
import './Toolbar.css';

//Components
import NavigationItems from "./NavigationItems/NavigationItems";
import logo from './../../../images/logo.png';
import MenuIcon from '@mui/icons-material/Menu';

class Toolbar extends Component {

    state = {
        isMenuShown:false
    }

    toggleMenu = () => {
        this.setState({isMenuShown:!this.state.isMenuShown});
    }

    render() {

        let style;

        if(this.state.isMenuShown) {
            style = {
                width:'256px'
            }
        }else{
            style = {
                width:'0'
            }
        }

        return (
            <div className='toolbar'>
                <div className='top-panel'>
                    <MenuIcon onClick={this.toggleMenu} className='menu-icon'/>
                </div>
                <div style={this.state.isMenuShown ? style : null} className='side-menu'>
                    <div className='logo-holder'>
                        <img className='logo' src={this.props.company && this.props.company.companyInformation && this.props.company.companyInformation.logo ? this.props.company.companyInformation.logo : logo} alt="Unternehmenslogo"/>
                    </div>
                    <NavigationItems
                        className='navigation-items'
                        user={this.props.user}
                        company={this.props.company}
                    />
                </div>
            </div>
        )
    }
}


export default Toolbar;