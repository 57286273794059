import React, {Component} from 'react';
import './CompanyAdministration.css';

//Components
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import ClearIcon from '@mui/icons-material/Clear';
import Button from "@mui/material/Button";
import api from "../../../../../api";
class CompanyAdministration extends Component {

    state = {
        companyInformation:this.props.company && this.props.company.companyInformation ? this.props.company.companyInformation : null
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps && this.props.company && this.props.company.companyInformation) {
            this.setState({companyInformation:this.props.company.companyInformation});
        }
    }

    companyNameOnChangeHandler = (e) => {
        let companyInformation = this.state.companyInformation;
        companyInformation.name = e.target.value;
        this.setState({companyInformation:companyInformation});
    }

    logoOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 1048576) {
            this.fileToDataUri(file)
                .then(dataUri => {
                        let companyInformation = this.state.companyInformation;
                        companyInformation.logo = dataUri;
                        this.setState({companyInformation:companyInformation});
                    }
                );
        }else{
            this.props.showMessage(2,"Das Logo darf maximal 1MB groß sein");
        }
    }

    fileToDataUri = (file) => new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            resolve(e.target.result);
        };
        fileReader.readAsDataURL(file);
    })

    deleteLogoOnClickHandler = () => {
        let companyInformation = this.state.companyInformation;
        companyInformation.logo = null;
        this.setState({companyInformation:companyInformation});
    }

    streetNameOnChangeHandler = (e) => {
        let companyInformation = this.state.companyInformation;
        companyInformation.streetName = e.target.value;
        this.setState({companyInformation:companyInformation});
    }

    streetNrOnChangeHandler = (e) => {
        let companyInformation = this.state.companyInformation;
        companyInformation.streetNr = e.target.value;
        this.setState({companyInformation:companyInformation});
    }

    addressAdditionOnChangeHandler = (e) => {
        let companyInformation = this.state.companyInformation;
        companyInformation.addressAddition = e.target.value;
        this.setState({companyInformation:companyInformation});
    }

    postCodeOnChangeHandler = (e) => {
        let companyInformation = this.state.companyInformation;
        companyInformation.postCode = e.target.value;
        this.setState({companyInformation:companyInformation});
    }

    cityOnChangeHandler = (e) => {
        let companyInformation = this.state.companyInformation;
        companyInformation.city = e.target.value;
        this.setState({companyInformation:companyInformation});
    }

    updateCompanyInformation = (companyInformation) => {
        const url = '/company/information';
        api.put(url,companyInformation)
            .then(response => {
                this.props.updateCompanyInformationInState(response.data);
                this.props.showMessage(0,"Die Unternehmensinformationen wurden erfolgreich gespeichert");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    render() {
        return (
            <div className='company-administration'>
                <div>

                </div>
                <h1>Unternehmensverwaltung</h1>
                {this.state.companyInformation ?
                    <div className='surrounder'>
                            <h2>Allgemein</h2>
                            <TextField value={this.state.companyInformation && this.state.companyInformation.name ? this.state.companyInformation.name : ''} onChange={this.companyNameOnChangeHandler} label='Name' className='field'/>
                            <Button variant="contained" type='main' component="label" className='field file-button' onChange={this.logoOnChangeHandler}>
                                Logo auswählen
                                <input type="file" hidden accept="image/*"/>
                            </Button>
                            {this.state.companyInformation && this.state.companyInformation.logo ? <img className='logo-preview' src={this.state.companyInformation.logo} alt="Company Logo"/> : null}
                            {this.state.companyInformation && this.state.companyInformation.logo ? <ClearIcon onClick={this.deleteLogoOnClickHandler} className='clear-icon'/> : null}
                            <h2>Adresse</h2>
                            <TextField value={this.state.companyInformation && this.state.companyInformation.streetName ? this.state.companyInformation.streetName : ''} onChange={this.streetNameOnChangeHandler} label='Straßenname' className='field'/>
                            <TextField value={this.state.companyInformation && this.state.companyInformation.streetNr ? this.state.companyInformation.streetNr : ''} onChange={this.streetNrOnChangeHandler} label='Hausnummer' className='field'/>
                            <TextField value={this.state.companyInformation && this.state.companyInformation.addressAddition ? this.state.companyInformation.addressAddition : ''} onChange={this.addressAdditionOnChangeHandler} label='Adresszusatz' className='field'/>
                            <TextField value={this.state.companyInformation && this.state.companyInformation.postCode ? this.state.companyInformation.postCode : ''} onChange={this.postCodeOnChangeHandler} label='Postleitzahl' className='field'/>
                            <TextField value={this.state.companyInformation && this.state.companyInformation.city ? this.state.companyInformation.city : ''} onChange={this.cityOnChangeHandler} label='Stadt' className='field'/>
                    </div> :
                    <h2>Lädt...</h2>}
                <ToolButton onClick={() => this.updateCompanyInformation(this.state.companyInformation)} main className='save-button'>Speichern</ToolButton>
            </div>
        )
    }
}

export default CompanyAdministration;