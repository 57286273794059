import React, {Component} from "react";
import './Actions.css';

//Components
import {Route} from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import MasterData from "./MasterData/MasterData";
import Administration from "./Administration/Administration";
import Settings from "./Settings/Settings";
import TripManagement from "./TripManagement/TripManagement";
import Reporting from "./Reporting/Reporting";

class Actions extends Component {
    render() {
        return (
            <div className='actions'>
                <Route exact path='/'>
                    <Dashboard
                        user={this.props.user}
                        showMessage={this.props.showMessage}
                        formatDate={this.props.formatDate}
                        formattedData={this.props.formattedData}
                    />
                </Route>

                <Route path='/trip-management'>
                    <TripManagement
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        formatDate={this.props.formatDate}
                        formatDateTime={this.props.formatDateTime}
                        loading={this.props.loading}
                        checkForNewTripRequests={this.props.checkForNewTripRequests}
                        checkForNewTrips={this.props.checkForNewTrips}
                        checkForNewTripSeries={this.props.checkForNewTripSeries}
                        checkForNewCompletedTrips={this.props.checkForNewCompletedTrips}

                        //Company
                        company={this.props.company}

                        // Companies
                        companies={this.props.companies}

                        // Patients
                        patients={this.props.patients}
                        addPatientToList={this.props.addPatientToList}
                        updatePatientInList={this.props.updatePatientInList}
                        removePatientFromList={this.props.removePatientFromList}

                        // Stations
                        stations={this.props.stations}
                        station={this.props.station}

                        // Trip Requests
                        tripRequests={this.props.tripRequests}
                        addTripRequestToList={this.props.addTripRequestToList}
                        updateTripRequestInList={this.props.updateTripRequestInList}
                        removeTripRequestFromList={this.props.removeTripRequestFromList}
                        generateTripRequestPDF={this.props.generateTripRequestPDF}

                        // Trips
                        trips={this.props.trips}
                        allTrips={this.props.allTrips}
                        addTripToList={this.props.addTripToList}
                        updateTripInList={this.props.updateTripInList}
                        removeTripFromList={this.props.removeTripFromList}

                        // Trip Series
                        tripSeries={this.props.tripSeries}
                        addTripSeriesToList={this.props.addTripSeriesToList}
                        updateTripSeriesInList={this.props.updateTripSeriesInList}
                        removeTripSeriesFromList={this.props.removeTripSeriesFromList}

                        // Completed Trips
                        completedTrips={this.props.completedTrips}
                        addCompletedTripToList={this.props.addCompletedTripToList}
                        removeCompletedTripFromList={this.props.removeCompletedTripFromList}
                    />
                </Route>

                <Route path='/reporting'>
                    <Reporting
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        formatDate={this.props.formatDate}
                        formatString={this.props.formatString}

                        //Company
                        company={this.props.company}

                        // Completed Trips
                        completedTrips={this.props.completedTrips}
                    />
                </Route>

                <Route path='/master-data'>
                    <MasterData
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        formatDate={this.props.formatDate}

                        //Company
                        company={this.props.company}

                        // Patients
                        patients={this.props.patients}
                        addPatientToList={this.props.addPatientToList}
                        updatePatientInList={this.props.updatePatientInList}
                        removePatientFromList={this.props.removePatientFromList}

                        // Stations
                        stations={this.props.stations}
                        addStationToList={this.props.addStationToList}
                        updateStationInList={this.props.updateStationInList}
                        removeStationFromList={this.props.removeStationFromList}

                        // Trips
                        trips={this.props.trips}
                        addTripToList={this.props.addTripToList}
                        removeTripFromList={this.props.removeTripFromList}

                        // Completed Trips
                        completedTrips={this.props.completedTrips}
                        addCompletedTripToList={this.props.addCompletedTripToList}
                        removeCompletedTripFromList={this.props.removeCompletedTripFromList}
                    />
                </Route>

                <Route path='/administration'>
                    <Administration
                        //Common
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}

                        //Company
                        company={this.props.company}
                        updateCompanyInformationInState={this.props.updateCompanyInformationInState}

                        //Users
                        user={this.props.user}
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUserInList={this.props.updateUserInList}
                        addUserToList={this.props.addUserToList}
                        removeUserFromList={this.props.removeUserFromList}
                    />
                </Route>

                <Route path='/settings'>
                    <Settings
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}
                        user={this.props.user}
                    />
                </Route>
            </div>
        )
    }
}

export default Actions;