import React, {Component} from "react";
import './ChatOverview.css';

//Components
import api from "../../../../api";
import Message from "./Message/Message";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../ToolButton/ToolButton";

class ChatOverview extends Component {

    state = {
        chatData:this.props.chatData,
        text:"",
        receiver:"",
        companyData:null,
        loading:false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.chatData !== this.state.chatData) {
            if(this.state.companyData && this.state.receiver && this.props.chatData.chatCompanies) {
                for(let i = 0; i < this.props.chatData.chatCompanies.length; i++) {
                    if(this.props.chatData.chatCompanies[i].abbreviation === this.state.companyData.abbreviation) {
                        for(let j = 0; j < this.props.chatData.chatCompanies[i].chatUsers.length; j++) {
                            if(this.props.chatData.chatCompanies[i].chatUsers[j].username === this.state.receiver.username) {
                                if(this.props.chatData.chatCompanies[i].chatUsers[j].messages.length !== this.state.receiver.messages.length) {
                                    this.setState({receiver:this.props.chatData.chatCompanies[i].chatUsers[j]},() => {
                                        this.scrollToBottom();
                                    });
                                }
                                return;
                            }
                        }
                    }
                }
            }
        }
    }

    sendMessageOnClickHandler = () => {
        if (this.state.text.trim().length > 0) {
            let message = {
                receiverAbbreviation: this.state.companyData.abbreviation,
                receiverName: this.state.receiver.username,
                senderAbbreviation: this.props.company.abbreviation,
                senderName: this.props.user.username,
                text: this.state.text
            }
            this.sendMessage(message);
        }
    };

    sendMessage = (message) => {
        console.log(message);
        let url = "/messages";
        this.setState({loading:true});
        api.post(url,message)
            .then(response => {
                let receiver = this.state.receiver;
                receiver.messages.push(message);
                message.creationTimestamp = new Date().toISOString();
                message.seen = true;
                this.setState({receiver:receiver,text:""}, () => {
                    this.setState({loading:false});
                    this.scrollToBottom();
                });
                console.log(response.data)
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    scrollToBottom = () => {
        let element = document.getElementById("message-panel");
        element.scrollTo(0,element.scrollHeight);
    }

    setReceiver = (receiverAbbreviation,receiver) => {
        console.log(receiver)
        if(this.state.companyData && this.state.receiver) {
            this.seenMessages(this.state.companyData.abbreviation,this.state.receiver);
        }
        this.setState({receiver:receiver},() => {
            this.scrollToBottom();
            this.seenMessages(receiverAbbreviation,receiver);
        });
    }

    seenMessages = (receiverAbbreviation,receiver) => {
        for(let i = receiver.messages.length - 1; i >= 0; i--) {
            if(receiver.messages[i].seen) {
                break;
            }else{
                receiver.messages[i].seen = true;
            }
        }
        this.setState({receiver:receiver});
        const url = '/messages/seen/' + receiverAbbreviation + "/" + receiver.username;
        api.put(url)
            .then(response => {
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    setCompanyData = (companyData) => {
        console.log(companyData);
        this.setState({receiver:null,companyData:companyData});
    };

    textOnChangeHandler = (e) => {
        this.setState({text:e.target.value});
    }

    pressEnter = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            this.sendMessageOnClickHandler();
        }
    }

    isSeenInUser = (messages) => {
        for(let i = messages.length - 1; i >= 0; i--) {
            if(this.props.company.abbreviation === messages[i].receiverAbbreviation && this.props.user.username === messages[i].receiverName) {
                return messages[i].seen;
            }
        }
        return true;
    }

    isSeenInCompany = (company) => {
        let seen = true;
        for(let i = 0; i < company.chatUsers.length; i++) {
            if(!this.isSeenInUser(company.chatUsers[i].messages)) {
                seen = false;
                break;
            }
        }
        return seen;
    }

    test = () => {
        console.log(this.props.company);
        console.log(this.props.user);
        console.log(this.state.receiver);
        console.log(this.state.chatData);
    }

    render() {
        return (
            <div className="chat-overview">
                <div className="user-overview">
                    {this.state.chatData && this.state.chatData.chatCompanies ? this.state.chatData.chatCompanies.map((companyData, index) => (
                        <div>
                            <div
                                className={!this.isSeenInCompany(companyData) ? "contacts-item company-contacts-item notification" : "contacts-item company-contacts-item"}
                                onClick={() => this.setCompanyData(companyData)}><p><b>{companyData.companyName}</b></p>
                            </div>
                            {this.state.companyData && this.state.companyData.abbreviation === companyData.abbreviation ?
                                <div>
                                    {companyData.chatUsers.map((user, index) => (
                                        !(this.state.companyData.abbreviation === this.props.company.abbreviation && this.props.user.username === user.username) ?
                                            <div
                                                onClick={() => this.setReceiver(companyData.abbreviation, user)}
                                                className={user.messages.length > 0 && !user.messages[user.messages.length - 1].seen ? "contacts-item user-contacts-item notification" : "contacts-item user-contacts-item"}>
                                                <p>{user.username}</p>
                                            </div> : null
                                    ))}
                                </div> : null}
                        </div>
                    )) : null}
                </div>
                <div id="message-panel" className="message-panel">
                    {this.props.company && this.props.user && this.state.receiver && this.state.receiver.messages ? this.state.receiver.messages.map((message, index) => (
                        <Message
                            user={this.props.user}
                            message={message}
                            company={this.props.company}
                            formatDateTime={this.props.formatDateTime}
                        />)) : null}
                </div>
                <div className="send-panel">
                    <TextField onKeyUp={(e) => this.pressEnter(e)} disabled={!this.state.receiver} fullWidth multiline
                               inputProps={{maxLength: 1000}} minRows={2} maxRows={2} className='multiline'
                               label="Nachricht" value={this.state.text} onChange={this.textOnChangeHandler}/>

                    <ToolButton disabled={this.state.loading || !this.state.receiver} className="send-button" main
                                onClick={this.sendMessageOnClickHandler}>Senden</ToolButton>
                </div>
            </div>
        )
    }
}

export default ChatOverview;