import React, {Component} from 'react';
import './Footer.css';

//Components

class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <p><a href="https://www.medimobil-fahrservice.de/" target="_blank" rel="noopener noreferrer">© 2011 Medimobil Fahrservice GmbH</a> | <a href="https://www.medimobil-fahrservice.de/index.php?id=22" target="_blank" rel="noopener noreferrer">Impressum</a> | <a href="https://www.medimobil-fahrservice.de/index.php?id=36" target="_blank" rel="noopener noreferrer">Datenschutz</a></p>
            </div>
        )
    }

}

export default Footer;
