import React, {Component} from "react";
import './SidePanel.css';
import ListItem from "../../../reusable/ListItem/ListItem";
import TripDialog from "../Actions/TripManagement/TripDialog/TripDialog";
import api from "../../../api";

//Components

class SidePanel extends Component {

    state = {
        upcomingTrips:[],
        upcomingTripSeries:[],
        activeTrip:null,
        type:null,

        // Dialog actions
        showTripDialog:false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.updateUpcomingTrips();
        }
    }

    updateUpcomingTrips = () => {
        let upcomingTrips = [];
        let upcomingTripSeries = [];
        let today = new Date();
        today = today.toISOString().substring(0,10);
        let weekday = new Date();
        switch (weekday.getDay()) {
            case 0:
                weekday = "sunday";
                break;
            case 1:
                weekday = "monday";
                break;
            case 2:
                weekday = "tuesday";
                break;
            case 3:
                weekday = "wednesday";
                break;
            case 4:
                weekday = "thursday";
                break;
            case 5:
                weekday = "friday";
                break;
            case 6:
                weekday = "saturday";
                break;
        }
        for(let i = 0; i < this.props.trips.length; i++) {
            if(this.props.trips[i].pickUpTime && this.props.trips[i].pickUpTime.substring(0,10) === today) {
                upcomingTrips.unshift(this.props.trips[i]);
            }
        }
        for(let i = 0; i < this.props.tripSeries.length; i++) {
            if(this.props.tripSeries[i].active && this.props.tripSeries[i][weekday]) {
                upcomingTripSeries.unshift(this.props.tripSeries[i]);
            }
        }
        this.setState({upcomingTrips:upcomingTrips,upcomingTripSeries:upcomingTripSeries});
    }

    // Dialog actions

    showTripDialog = (trip,type) => {
        this.setState({activeTrip:trip,type:type,showTripDialog:true});
    }

    closeTripDialog = () => {
        this.setState({showTripDialog:false});
    }

    render() {
        return (
            <div onKeyDown={this._handleKeyDown} className='side-panel'>

                <TripDialog
                    open={this.state.showTripDialog}
                    close={this.closeTripDialog}
                    showMessage={this.props.showMessage}
                    trip={this.state.activeTrip}
                    completeTrip={this.completeTrip}
                    type={this.state.type}
                    formatDate={this.props.formatDate}
                    formatDateTime={this.props.formatDateTime}
                />

                <h1>Heutige Fahrten</h1>

                {this.state.upcomingTrips.sort((a,b) => a.pickUpTime < b.pickUpTime ? 1 : -1).map((trip,index) => (
                    <ListItem
                        key={index}
                        type="trip"
                        item={trip}
                        showItemDialog={() => this.showTripDialog(trip,"trip")}
                        formatDate={this.props.formatDate}
                        company={this.props.company}
                        sidepanel={true}
                    />
                ))}

                <h1>Heutige Serienfahrten</h1>

                {this.state.upcomingTripSeries.sort((a,b) => a.pickUpTime < b.pickUpTime ? 1 : -1).map((tripSeries,index) => (
                    <ListItem
                        key={index}
                        type="trip-series"
                        item={tripSeries}
                        showItemDialog={() => this.showTripDialog(tripSeries,"trip-series")}
                        formatDate={this.props.formatDate}
                        company={this.props.company}
                        sidepanel={true}
                    />
                ))}
            </div>
        )
    }
}

export default SidePanel;