import React, {Component} from "react";
import './YesCancelDialog.css';

//Components
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

class YesCancelDialog extends Component {

    onClick = () => {
        this.props.onClick();
        this.props.close();
    }

    render() {
        return (
            <Dialog open={this.props.open} onClose={this.props.close} className='yes-cancel-dialog'>
                <DialogTitle>{this.props.header}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{this.props.text}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={this.props.close}>
                        <b>Abbrechen</b>
                    </Button>
                    <Button onClick={this.onClick} color="primary">
                        <b>Ja</b>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default YesCancelDialog;