import React, {Component} from 'react';
import './TripRequestOverview.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@mui/material/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import api from "../../../../../api";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import AddIcon from "@mui/icons-material/Add";
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import TripDialog from "../TripDialog/TripDialog";

class TripRequestOverview extends Component {

    state = {
        shownTripRequests: [],
        searchWord:"",
        searchType:"patientLastName",
        activeCompany:"all",
        activeTripRequest:null,

        //Dialogs
        showTripRequestDialog:false,
        showDeleteTripRequestDialog:false
    }

    companySearchOnChangeHandler = (company) => {
        this.setState({activeCompany:company});
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchWord:"",searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownTripRequests = [];
            switch (this.state.searchType) {
                case "patientFirstName":
                    this.props.tripRequests.forEach(tripRequest => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === tripRequest.abbreviation) && tripRequest.patientFirstName && tripRequest.patientFirstName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTripRequests.push(tripRequest);
                        }
                    });
                    break;
                case "patientLastName":
                    this.props.tripRequests.forEach(tripRequest => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === tripRequest.abbreviation) && tripRequest.patientLastName && tripRequest.patientLastName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTripRequests.push(tripRequest);
                        }
                    });
                    break;
                case "postCode":
                    this.props.tripRequests.forEach(tripRequest => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === tripRequest.abbreviation) && tripRequest.postCode && tripRequest.postCode.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownTripRequests.push(tripRequest);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownTripRequests:shownTripRequests});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    createTripRequest = (tripRequest) => {
        const url = '/trip-requests';
        api.post(url,tripRequest)
            .then(response => {
                this.props.addTripRequestToList(response.data.tripRequest);
                if(response.data.patient) {
                    this.props.addPatientToList(response.data.patient);
                }
                this.searchOnClick();
                this.closeTripRequestDialog();
                this.props.showMessage(0,"Die Fahrtanfrage wurde erfolgreich erstellt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    confirmTripRequest = (tripRequest) => {
        const url = '/trip-requests/confirm';
        api.post(url,tripRequest)
            .then(response => {
                this.props.removeTripRequestFromList(tripRequest);
                if(response.data.trip) {
                    this.props.addTripToList(response.data.trip);
                }
                if(response.data.returnTrip) {
                    this.props.addTripToList(response.data.returnTrip);
                }
                if(response.data.tripSeries) {
                    this.props.addTripSeriesToList(response.data.tripSeries);
                }
                this.searchOnClick();
                this.closeTripRequestDialog();
                this.props.showMessage(0,"Die Fahrtanfrage wurde erfolgreich bestätigt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    rejectTripRequest = (tripRequest) => {
        const url = '/trip-requests/reject';
        api.post(url,tripRequest)
            .then(response => {
                this.props.removeTripRequestFromList(tripRequest);
                this.searchOnClick();
                this.closeTripRequestDialog();
                this.props.showMessage(0,"Die Fahrtanfrage wurde erfolgreich abgelehnt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteTripRequest = (tripRequest) => {
        const url = '/trip-request/' + tripRequest.id;
        api.delete(url)
            .then(response => {
                this.props.removeTripRequestFromList(tripRequest);
                this.searchOnClick();
                this.closeDeleteTripRequestDialog();
                this.props.showMessage(0,"Die Fahrtanfrage wurde erfolgreich gelöscht");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    saveChangeRequest = (tripRequest) => {
        const url = '/trip-requests/change-request';
        api.put(url,tripRequest)
            .then(response => {
                this.props.updateTripRequestInList(response.data);
                this.searchOnClick();
                this.closeTripRequestDialog();
                this.props.showMessage(0,"Die Änderungsanfrage wurde erfolgreich verschickt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    confirmChangedTripRequest = (tripRequest) => {
        let url = "";
        if(tripRequest.tripSeries) {
            url = '/trip-requests/change-request/trip-series/confirm';
        }else{
            url = '/trip-requests/change-request/trip/confirm';
        }
        api.post(url,tripRequest)
            .then(response => {
                this.props.removeTripRequestFromList(tripRequest);
                if(tripRequest.tripSeries) {
                    this.props.addTripSeriesToList(response.data);
                }else{
                    this.props.addTripToList(response.data);
                }
                this.searchOnClick();
                this.closeTripRequestDialog();
                this.props.showMessage(0,"Die Änderungsanfrage wurde erfolgreich bestätigt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    rejectChangedTripRequest = (tripRequest) => {
        const url = '/trip-requests/change-request/reject';
        api.post(url,tripRequest)
            .then(response => {
                this.props.removeTripRequestFromList(tripRequest);
                this.searchOnClick();
                this.closeTripRequestDialog();
                this.props.showMessage(0,"Die Änderungsanfrage wurde erfolgreich abgelehnt");
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    setTripRequestOpened = (tripRequest) => {
        let url = "";
        if(this.props.company && this.props.company.rights === 1) {
            tripRequest.openedMaster = true;
            url = "/trip-requests/opened-master";
        }else{
            tripRequest.openedCustomer = true;
            url = "/trip-requests/opened-customer";
        }
        api.post(url,tripRequest)
            .then(response => {

            })
    }

    //Dialog actions

    showTripRequestDialog = (tripRequest) => {
        if(this.props.company && ((this.props.company.rights === 1 && !tripRequest.openedMaster) || (this.props.company.rights === 2 && !tripRequest.openedCustomer))) {
            this.setTripRequestOpened(tripRequest);
            this.props.checkForNewTripRequests(this.props.company,this.props.tripRequests);
        }
        this.setState({activeTripRequest:tripRequest,showTripRequestDialog:true});
    }

    closeTripRequestDialog = () => {
        this.setState({showTripRequestDialog:false});
    }

    showDeleteTripRequestDialog = (tripRequest) => {
        this.setState({activeTripRequest:tripRequest,showDeleteTripRequestDialog:true});
    }

    closeDeleteTripRequestDialog = () => {
        this.setState({showDeleteTripRequestDialog:false});
    }

    render() {

        let blankTripRequest = {
            patient:{},
            countryFrom:"Deutschland",
            countryTo:"Deutschland",
            stationName:this.props.stationName
        }

        return (
            <div className='trip-request-overview'>
                <ReactTooltip/>

                <TripDialog
                    open={this.state.showTripRequestDialog}
                    close={this.closeTripRequestDialog}
                    showMessage={this.props.showMessage}
                    trip={this.state.activeTripRequest}
                    createTripRequest={this.createTripRequest}
                    updateTripRequest={this.updateTripRequest}
                    deleteTripRequest={this.deleteTripRequest}
                    confirmTripRequest={this.confirmTripRequest}
                    rejectTripRequest={this.rejectTripRequest}
                    countries={this.props.countries}
                    formatDate={this.props.formatDate}
                    formatDateTime={this.props.formatDateTime}
                    type={"trip-request"}
                    generateTripRequestPDF={this.props.generateTripRequestPDF}
                    saveChangeRequest={this.saveChangeRequest}
                    confirmChangedTrip={this.confirmChangedTripRequest}
                    rejectChangedTrip={this.rejectChangedTripRequest}

                    // Patients
                    patients={this.props.patients}
                    addPatientToList={this.props.addPatientToList}

                    // Stations
                    stationName={this.props.stationName}
                    stations={this.props.stations}
                    station={this.props.station}

                    //Company
                    company={this.props.company}
                />

                <YesCancelDialog
                    open={this.state.showDeleteTripRequestDialog}
                    close={this.closeDeleteTripRequestDialog}
                    header='Löschen'
                    text={'Wollen Sie die Fahrtanfrage wirklich löschen?'}
                    onClick={() => this.deleteTripRequest(this.state.activeTripRequest)}
                />

                <div className='header-bar'>
                    <h1>Fahrtanfragen</h1>
                    {this.props.company && this.props.company.rights === 1 ? <TextField value={this.state.activeCompany} onChange={(e) => this.companySearchOnChangeHandler(e.target.value)} select className='search-select field' label='Unternehmen'>
                        <MenuItem key={0} value={"all"}>Alle</MenuItem>
                        {this.props.companies ? this.props.companies.map((company,index) => (
                            <MenuItem key={index + 1} value={company}>{company.displayName}</MenuItem>
                        )) : []}
                    </TextField> : null}
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-select' label='Suchwort'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'patientFirstName'}>Vorname</MenuItem>
                        <MenuItem key={1} value={'patientLastName'}>Nachname</MenuItem>
                        <MenuItem key={2} value={'postCode'}>Postleitzahl</MenuItem>
                    </TextField>
                    <ToolButton disabled={this.props.loading} main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownTripRequests.sort((a,b) => a.pickUpTime < b.pickUpTime ? 1 : -1).map((tripRequest,index) => (
                        <ListItem
                            key={index}
                            type="trip-request"
                            item={tripRequest}
                            showItemDialog={() => this.showTripRequestDialog(tripRequest)}
                            showDeleteItemDialog={() => this.showDeleteTripRequestDialog(tripRequest)}
                            formatDate={this.props.formatDate}
                            company={this.props.company}
                        />
                    ))}
                </div>
                {this.props.company && this.props.company.rights === 2 ? <ToolFab className='add-fab' onClick={() => this.showTripRequestDialog(blankTripRequest)} aria-label="add">
                    <AddIcon/>
                </ToolFab> : null}
            </div>
        )
    }
}

export default TripRequestOverview;