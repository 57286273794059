import React, {Component} from 'react';
import './CompletedTripOverview.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@mui/material/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import TripDialog from "../TripDialog/TripDialog";
import api from "../../../../../api";

class CompletedTripOverview extends Component {

    state = {
        shownCompletedTrips: [],
        searchWord:"",
        searchType:"patientLastName",
        activeCompany:"all",

        //Dialogs
        showCompletedTripDialog:false,
        showDeleteCompletedTripDialog:false
    }

    companySearchOnChangeHandler = (company) => {
        this.setState({activeCompany:company});
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchWord:"",searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownCompletedTrips = [];
            switch (this.state.searchType) {
                case "patientFirstName":
                    this.props.completedTrips.forEach(completedTrip => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === completedTrip.abbreviation) && completedTrip.patientFirstName && completedTrip.patientFirstName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownCompletedTrips.push(completedTrip);
                        }
                    });
                    break;
                case "patientLastName":
                    this.props.completedTrips.forEach(completedTrip => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === completedTrip.abbreviation) && completedTrip.patientLastName && completedTrip.patientLastName.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownCompletedTrips.push(completedTrip);
                        }
                    });
                    break;
                case "postCode":
                    this.props.completedTrips.forEach(completedTrip => {
                        if((this.state.activeCompany === "all" || this.state.activeCompany.abbreviation === completedTrip.abbreviation) && completedTrip.postCode && completedTrip.postCode.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownCompletedTrips.push(completedTrip);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownCompletedTrips:shownCompletedTrips});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    setCompletedTripOpened = (completedTrip) => {
        let url = "";
        if(this.props.company && this.props.company.rights === 1) {
            completedTrip.openedMaster = true;
            url = "/completed-trips/opened-master";
        }else{
            completedTrip.openedCustomer = true;
            url = "/completed-trips/opened-customer";
        }
        api.post(url,completedTrip)
            .then(response => {

            })
    }

    //Dialog actions

    showTripDialog = (completedTrip) => {
        if(this.props.company && ((this.props.company.rights === 1 && !completedTrip.openedMaster) || (this.props.company.rights === 2 && !completedTrip.openedCustomer))) {
            this.setCompletedTripOpened(completedTrip);
            this.props.checkForNewCompletedTrips(this.props.company,this.props.completedTrips);
        }
        this.setState({activeCompletedTrip:completedTrip,showTripDialog:true});
    }

    closeTripDialog = () => {
        this.setState({showTripDialog:false});
    }

    render() {

        return (
            <div className='completed-trip-overview'>
                <ReactTooltip/>

                <TripDialog
                    open={this.state.showTripDialog}
                    close={this.closeTripDialog}
                    showMessage={this.props.showMessage}
                    trip={this.state.activeCompletedTrip}
                    countries={this.props.countries}
                    formatDate={this.props.formatDate}
                    completeTrip={this.completeTrip}
                    type={"completed-trip"}

                    //Company
                    company={this.props.company}

                    //Patients
                    patients={this.props.patients}

                    // Stations
                    stations={this.props.stations}
                />

                <div className='header-bar'>
                    <h1>Abgeschlossene Fahrten</h1>
                    {this.props.company && this.props.company.rights === 1 ? <TextField value={this.state.activeCompany} onChange={(e) => this.companySearchOnChangeHandler(e.target.value)} select className='search-select field' label='Unternehmen'>
                        <MenuItem key={0} value={"all"}>Alle</MenuItem>
                        {this.props.companies ? this.props.companies.map((company,index) => (
                            <MenuItem key={index + 1} value={company}>{company.displayName}</MenuItem>
                        )) : []}
                    </TextField> : null}
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-select' label='Suchwort'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'patientFirstName'}>Vorname</MenuItem>
                        <MenuItem key={1} value={'patientLastName'}>Nachname</MenuItem>
                        <MenuItem key={2} value={'postCode'}>Postleitzahl</MenuItem>
                    </TextField>
                    <ToolButton disabled={this.props.loading} main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownCompletedTrips.sort((a,b) => a.pickUpTime < b.pickUpTime ? 1 : -1).map((trip,index) => (
                        <ListItem
                            key={index}
                            type="trip"
                            item={trip}
                            showItemDialog={() => this.showTripDialog(trip)}
                            formatDate={this.props.formatDate}
                            company={this.props.company}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default CompletedTripOverview;