import React, {Component} from 'react';
import './ItemDialog.css';

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";

class ItemDialog extends Component {

    state = {
        item:this.props.item
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props !== prevProps) {
            this.setState({item:this.props.item});
        }
    }

    //Change Handler

    //Common

    nameOnChangeHandler = (e) => {
        let item = this.state.item;
        item.name = e.target.value;
        this.setState({item:item});
    }

    toggleWalkable = () => {
        let item = this.state.item;
        item.walkable = !item.walkable;
        this.setState({item:item});
    }

    firstNameOnChangeHandler = (e) => {
        let item = this.state.item;
        item.firstName = e.target.value;
        this.setState({item:item});
    }

    lastNameOnChangeHandler = (e) => {
        let item = this.state.item;
        item.lastName = e.target.value;
        this.setState({item:item});
    }

    birthdateOnChangeHandler = (e) => {
        let item = this.state.item;
        item.birthdate = e.target.value;
        this.setState({item:item});
    }

    addItemOnClickHandler = () => {
        switch (this.props.type) {
            case "patient":
                if(this.state.item.firstName
                    && this.state.item.lastName
                    && this.state.item.birthdate) {
                    if(this.state.item.id) {
                        this.props.updateItem(this.state.item);
                        this.props.close();
                    }else{
                        this.props.addNewItem(this.state.item);
                        this.props.close();
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte");
                }
                break;
            case "station":
                if(this.state.item.name) {
                    if(this.state.item.id) {
                        this.props.updateItem(this.state.item);
                        this.props.close();
                    }else{
                        this.props.addNewItem(this.state.item);
                        this.props.close();
                    }
                }else{
                    this.props.showMessage(2,"Fehlende oder falsche Werte");
                }
                break;
            default:
                break;
        }
    }

    render() {

        let title = 'unbekannt';

        let fields = null;

        switch (this.props.type) {
            case "patient":
                title = this.props.isNewItem ? 'Patient anlegen' : 'Patient bearbeiten';
                fields = <div>
                    <h2>Person</h2>
                    <TextField
                        value={this.state.item.firstName}
                        onChange={this.firstNameOnChangeHandler}
                        className='field'
                        label='Vorname'
                        disabled={this.state.item.id}
                    />
                    <TextField
                        value={this.state.item.lastName}
                        onChange={this.lastNameOnChangeHandler}
                        className='field'
                        label='Nachname'
                        disabled={this.state.item.id}
                    />
                    <TextField
                        value={this.state.item.birthdate}
                        onChange={this.birthdateOnChangeHandler}
                        className='field'
                        label='Geburtsdatum'
                        disabled={this.state.item.id}
                        type='date'
                    />
                </div>
                break;
            case "station":
                title = this.props.isNewItem ? 'Station anlegen' : 'Station bearbeiten';
                fields = <div className="flex">
                    <TextField
                        value={this.state.item.name}
                        onChange={this.nameOnChangeHandler}
                        className='field'
                        label='Name'
                    />
                    <FormControlLabel
                        className='field checkbox' control={<Checkbox
                        onChange={this.toggleWalkable}
                        value={this.state.item.walkable}
                        checked={this.state.item.walkable}
                    />} label="Gehfähig" />
                </div>
                break;
            default:
                break;
        }

        return (
            <Dialog scroll='body' type={this.props.type} open={this.props.open} onClose={this.props.close} className='add-item-dialog'>
                <DialogTitle className='title'><h1>{title}</h1></DialogTitle>
                <DialogContent>
                    {fields}
                </DialogContent>
                <DialogActions>
                    <ToolButton onClick={this.addItemOnClickHandler} main className='button'>{!this.state.item.id ? 'Anlegen' : 'Speichern'}</ToolButton>
                </DialogActions>
            </Dialog>
        );
    }
}

export default ItemDialog;