import React, {Component} from "react";
import './ActionsMenu.css';

//Components
import {NavLink, Route} from "react-router-dom";

class ActionsMenu extends Component {
    render() {
        return (
        <div className='actions-menu'>
            <Route path='/trip-management'>
                <menu className='trip-management-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/trip-management'>
                        <h2>{this.props.containsNewTripRequests ? <div className="notification"></div> : null} Fahrtanfragen{this.props.tripRequests && this.props.tripRequests.length && this.props.tripRequests.length > 0 ? " (" + this.props.tripRequests.length + ")" : ""}</h2>
                    </NavLink>
                    <NavLink className='nav-item' activeClassName='active' exact to='/trip-management/trip-overview'>
                        <h2>{this.props.containsNewTrips ? <div className="notification"/> : null} Fahrtenübersicht{this.props.trips && this.props.trips.length && this.props.trips.length > 0 ? " (" + this.props.trips.length + ")" : ""}</h2>
                    </NavLink>
                    <NavLink className='nav-item' activeClassName='active' exact to='/trip-management/trip-series-overview'>
                        <h2>{this.props.containsNewTripSeries ? <div className="notification"/> : null} Serienfahrten{this.props.tripSeries && this.props.tripSeries.length && this.props.tripSeries.length > 0 ? " (" + this.props.tripSeries.length + ")" : ""}</h2>
                    </NavLink>
                    <NavLink className='nav-item' activeClassName='active' exact to='/trip-management/completed-trip-overview'>
                        <h2>{this.props.containsNewCompletedTrips ? <div className="notification"/> : null} Abgeschl. Fahrten{this.props.completedTrips && this.props.completedTrips.length && this.props.completedTrips.length > 0 ? " (" + this.props.completedTrips.length + ")" : ""}</h2>
                    </NavLink>
                </menu>
            </Route>
            <Route path='/master-data'>
                <menu className='master-data-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/master-data'><h2>Patienten</h2>
                    </NavLink>
                    <NavLink className='nav-item' activeClassName='active' exact to='/master-data/stations'>
                        <h2>Stationen</h2></NavLink>
                </menu>
            </Route>
            <Route path='/administration'>
                <menu className='administration-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/administration'><h2>Benutzerverwaltung</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/administration/company'><h2>Unternehmensverwaltung</h2></NavLink>
                </menu>
            </Route>
            <Route path='/settings'>
                <menu className='settings-menu'>
                    <NavLink className='nav-item' activeClassName='active' exact to='/settings'><h2>Allgemein</h2></NavLink>
                    <NavLink className='nav-item' activeClassName='active' to='/settings/security'><h2>Sicherheit</h2></NavLink>
                </menu>
            </Route>
            </div>
        )
    }
}

export default ActionsMenu;