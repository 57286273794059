import React, {Component} from "react";
import './FileDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import ToolButton from "../ToolButton/ToolButton";
import CloseIcon from '@mui/icons-material/Close';
import ReactTooltip from 'react-tooltip';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

class FileDialog extends Component {

    state = {
        multipartFile:null
    }

    fileOnChangeHandler = (e) => {
        let file = e.target.files[0];
        if(file.size < 10000000) {
            this.setState({multipartFile:file});
        }else{
            this.props.showMessage(2,"Die Datei darf maximal 10MB groß sein");
        }
    }

    saveItemOnClickHandler = () => {
        if (this.state.multipartFile !== null) {
            this.props.addNewFileToItem(this.props.item,this.state.multipartFile);
            this.props.close();
        } else {
            this.props.showMessage(2, 'Fehlende oder falsche Werte');
        }
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='file-dialog'>
                <ReactTooltip/>
                <DialogTitle>Datei anhängen</DialogTitle>
                <DialogContent>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <Button main variant="contained" type='main' component="label" className='field button' onChange={this.fileOnChangeHandler}>
                        {this.state.multipartFile ? this.state.multipartFile.name : "Datei auswählen"}
                        <input type="file" hidden accept="*"/>
                    </Button>
                    <DialogActions>
                        <ToolButton main className='button' onClick={this.saveItemOnClickHandler}>Speichern</ToolButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
}

export default FileDialog;