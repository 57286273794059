import React, {Component} from 'react';
import './UserDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import ToolButton from "../../../../../../reusable/ToolButton/ToolButton";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

class UserDialog extends Component {

    //On Change Handler

    saveUserOnClickHandler = (user) => {
        if(user.username && user.password && user.rights) {
            if(this.props.user.id) {
                this.props.updateUser(user);
            }else{
                this.props.createNewUser(user);
            }
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='user-dialog'>
                <div className='surrounder'>
                    <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                    <DialogTitle><h1 className='item-name'>Benutzer</h1></DialogTitle>
                    <DialogContent>
                        <h2>Anmeldung</h2>
                        <div>
                            <TextField disabled={this.props.user ? this.props.user.id : false} value={this.props.user && this.props.user.username ? this.props.user.username : ""} onChange={this.props.usernameOnChangeHandler} className='field' label='Benutzername'/>
                            {this.props.user && !this.props.user.id ? <TextField value={this.props.user && this.props.user.password ? this.props.user.password : ""} onChange={this.props.passwordOnChangeHandler} type='password' className='field' label='Password'/> : null}
                            {this.props.user ? <TextField value={this.props.user && this.props.user.rights ? this.props.user.rights : ""} onChange={this.props.rightsOnChangeHandler} select className='field' label='Rechte'>
                                <MenuItem key={1} value={1}>Admin</MenuItem>
                                <MenuItem disabled={this.props.user && this.props.user.rights && this.props.user.rights === 1} key={2} value={2}>Mitarbeiter</MenuItem>
                            </TextField> : null}
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <ToolButton className='button' main onClick={() => this.saveUserOnClickHandler(this.props.user)}>Speichern</ToolButton>
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

export default UserDialog;