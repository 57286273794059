import React, {Component} from "react";
import './NavigationItems.css';

//Components
import {NavLink} from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ReactTooltip from "react-tooltip";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import BarChartIcon from '@mui/icons-material/BarChart';

class NavigationItems extends Component {
    render() {
        return (
            <menu className='navigation-items'>
                <ReactTooltip/>
                <NavLink className='nav-item' activeClassName='active' exact to='/'><h2><DashboardIcon className='icon'/> Übersicht</h2></NavLink>
                <NavLink className='nav-item' activeClassName='active' to='/trip-management'><h2><DirectionsCarIcon className='icon'/> Fahrtenverwaltung</h2></NavLink>
                {this.props.user && this.props.user.role === 'ADMIN' ? <NavLink className='nav-item' activeClassName='active' to='/reporting'><h2><BarChartIcon className='icon'/> Reporting</h2></NavLink> : null}
                {this.props.company && this.props.company.rights === 2 && this.props.user && (this.props.user.role === 'ADMIN' || this.props.user.role === 'USER') ? <NavLink className='nav-item' activeClassName='active' to='/master-data'><h2><StorageIcon className='icon'/> Stammdaten</h2></NavLink> : null}
                {this.props.user && this.props.user.role === 'ADMIN' ? <NavLink className='nav-item' activeClassName='active' to='/administration'><h2><SupervisorAccountIcon className='icon'/> Administration</h2></NavLink> : null}
                <NavLink className='nav-item' activeClassName='active' to='/settings'><h2><SettingsIcon className='icon'/> Einstellungen</h2></NavLink>
            </menu>
        )
    }
}

export default NavigationItems;