import React, {Component} from 'react';
import './ListItem.css';

//Components
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ReactTooltip from "react-tooltip";
import DeleteIcon from "@mui/icons-material/Delete";

class ListItem extends Component {

    render() {
        return (
            <div key={this.props.key} className={`list-item box-shadow ${!this.props.sidepanel && (this.props.type === "trip-request" || this.props.type === "trip" || this.props.type === "trip-series" || this.props.type === "completed-trip") && this.props.company && ((this.props.company.rights === 1 && !this.props.item.openedMaster) || (this.props.company.rights === 2 && !this.props.item.openedCustomer)) ? 'pulse' : ''}`}>
                <ReactTooltip/>
                {this.props.type === "trip-request" && this.props.item && this.props.item.changeRequest ? <div data-tip="Laufende Änderungsanfrage" style={{background:"lightcoral"}} className='status'/> : null}
                {this.props.type === "trip-series" && this.props.item && !this.props.item.active ? <div data-tip="Inaktiv" style={{background:"lightgray"}} className='status'/> : null}
                {this.props.deletable ? <DeleteIcon data-tip="Löschen" onClick={this.props.showDeleteItemDialog} className='icon delete-icon'/> : null}
                <DocumentScannerIcon data-tip="Anzeigen" onClick={this.props.showItemDialog} className='icon show-icon'/>
                {this.props.type === "user" && this.props.currentUser && this.props.currentUser.rights === 1 && this.props.currentUser.id !== this.props.item.id ? <DeleteIcon onClick={this.props.showDeleteItemDialog} data-tip="Löschen" className='icon delete-icon'/> : null}
                <p className='info-small'>
                    {this.props.type === "user" && this.props.item.rights === 1 ? "Admin" : null}
                    {this.props.type === "user" && this.props.item.rights === 2 ? "Mitarbeiter" : null}
                    {this.props.item.pickUpTime && (this.props.type === "trip-request" || this.props.type === "trip") ? this.props.formatDate(this.props.item.pickUpTime) + " " : null}
                    {((this.props.type === "trip-request" || this.props.type === "trip-series") && (this.props.item.monday || this.props.item.returnTripMonday)) ? "Mo " : null}
                    {((this.props.type === "trip-request" || this.props.type === "trip-series") && (this.props.item.tuesday || this.props.item.returnTripTuesday)) ? "Di " : null}
                    {((this.props.type === "trip-request" || this.props.type === "trip-series") && (this.props.item.wednesday || this.props.item.returnTripWednesday)) ? "Mi " : null}
                    {((this.props.type === "trip-request" || this.props.type === "trip-series") && (this.props.item.thursday || this.props.item.returnTripThursday)) ? "Do " : null}
                    {((this.props.type === "trip-request" || this.props.type === "trip-series") && (this.props.item.friday || this.props.item.returnTripFriday)) ? "Fr " : null}
                    {((this.props.type === "trip-request" || this.props.type === "trip-series") && (this.props.item.saturday || this.props.item.returnTripSaturday)) ? "Sa " : null}
                    {((this.props.type === "trip-request" || this.props.type === "trip-series") && (this.props.item.sunday || this.props.item.returnTripSunday)) ? "So " : null}
                </p>
                <h3 className='info-big'>
                    {this.props.type === "user" ? this.props.item.username : null}
                    {(this.props.type === "trip-request" || this.props.type === "trip" || this.props.type === "trip-series") && this.props.item.patientFirstName && this.props.item.patientLastName && this.props.item.patientBirthdate ? this.props.item.patientFirstName + " " + this.props.item.patientLastName + " (" + this.props.formatDate(this.props.item.patientBirthdate) + ")" : null}
                </h3>
                <p className='info-tiny'>{this.props.company && this.props.company.rights === 1 && (this.props.type === "trip-request" || this.props.type === "trip" || this.props.type === "trip-series") && this.props.item.companyName ? "Angefragt von " + this.props.item.companyName : null}</p>

            </div>
        )
    }
}

export default ListItem;