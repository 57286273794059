import React, {Component} from 'react';
import './MasterDataContent.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import AddIcon from '@mui/icons-material/Add';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import MasterDataItem from "./MasterDataItem/MasterDataItem";
import ItemDialog from "./ItemDialog/ItemDialog";
import TextField from "@mui/material/TextField";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import ReactTooltip from "react-tooltip";
import api from "../../../../../api";

class MasterDataContent extends Component {

    state = {
        shownItems: [],
        searchWord:'',
        searchType:this.props.type==="patient" ? 'lastName' : this.props.type==="station" ? "name" : "",
        selectedItem:null,
        action:null,
        item: {
            nr:'',
            firstName:'',
            lastName:'',
            country:'Deutschland'
        },

        //Dialogs
        showItemDialog:false,
        showDeleteItemDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchWord:"",searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownItems = [];
            this.props.items.forEach(element => {
                if(element[this.state.searchType] && element[this.state.searchType].toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                    shownItems.push(element);
                }
            });
            this.setState({shownItems:shownItems});
        }else{
            this.props.showMessage(2,'Wählen Sie eine Suche aus');
        }
    }

    addNewItem = (item) => {
        let url;
        switch (this.props.type) {
            case "patient":
                url = "/patients";
                break;
            case "station":
                url = "/stations";
                break;
        }
        api.post(url,item)
            .then(response => {
                let message;
                switch (this.props.type) {
                    case "patient":
                        message = "Der Patient wurde erfolgreich angelegt";
                        break;
                    case "station":
                        message = "Die Station wurde erfolgreich angelegt";
                        break;
                }
                this.props.addItemToList(response.data);
                this.props.showMessage(0,message);
                this.searchOnClick();
                this.closeItemDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateItem = (item) => {
        let url;
        switch (this.props.type) {
            case "patient":
                url = "/patients";
                break;
            case "station":
                url = "/stations";
                break;
        }
        api.put(url,item)
            .then(response => {
                let message;
                switch (this.props.type) {
                    case "patient":
                        message = "Die Änderungen wurden erfolgreich gespeichert";
                        break;
                    case "station":
                        message = "Die Änderungen wurden erfolgreich gespeichert";
                        break;
                }
                this.props.updateItemInList(response.data);
                this.props.showMessage(0,message);
                this.searchOnClick();
                this.closeItemDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteItem = (item) => {
        let url;
        switch (this.props.type) {
            case "patient":
                url = "/patients/" + item.id;
                break;
            case "station":
                url = "/stations/" + item.id;
                break;
        }
        api.delete(url)
            .then(response => {
                let message;
                switch (this.props.type) {
                    case "patient":
                        message =  "Der Patient wurde erfolgreich gelöscht"
                        break;
                    case "station":
                        message =  "Die Station wurde erfolgreich gelöscht"
                        break;
                }
                this.props.showMessage(0,message);
                this.props.removeItemFromList(item);
                this.searchOnClick();
                this.closeDeleteItemDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions
    showItemDialog = (isNewItem,item) => {
        this.setState({isNewItem:isNewItem,item:item,showItemDialog:true});
    }

    closeItemDialog = () => {
        this.setState({showItemDialog:false});
    }

    showDeleteItemDialog = (item) => {
        this.setState({item:item,showDeleteItemDialog:true});
    }

    closeDeleteItemDialog = () => {
        this.setState({showDeleteItemDialog:false});
    }

    render() {

        const blankItem = {
            nr:'',
            name:'',
            firstName:'',
            lastName:'',
            country:'Deutschland'
        }

        let searchTypes = [];
        let title = null;
        let deleteText = null;

        switch (this.props.type) {
            case "patient":
                searchTypes = [
                    {
                        value:'firstName',
                        label:'Vorname'
                    },
                    {
                        value:'lastName',
                        label:'Nachname'
                    },
                    {
                        value:'birthdate',
                        label:'Geburtsdatum'
                    }
                ];
                title = 'Patient';
                deleteText = 'Wollen Sie den Patienten wirklich löschen?';
                break;
            case "station":
                searchTypes = [
                    {
                        value:'name',
                        label:'Name'
                    }
                ];
                title = 'Station';
                deleteText = 'Wollen Sie die Station wirklich löschen?';
                break;
            default:
                break;
        }

        return (
            <div className='master-data-content'>
                <ReactTooltip/>

                <ItemDialog
                    open={this.state.showItemDialog}
                    close={this.closeItemDialog}
                    type={this.props.type}
                    addNewItem={this.addNewItem}
                    updateItem={this.updateItem}
                    showMessage={this.props.showMessage}
                    item={this.state.item}
                    isNewItem={this.state.isNewItem}
                    countries={this.props.countries}
                />

                <YesCancelDialog
                    open={this.state.showDeleteItemDialog}
                    close={this.closeDeleteItemDialog}
                    header='Löschen'
                    text={deleteText}
                    onClick={() => this.deleteItem(this.state.item)}
                />

                <div className='header-bar'>
                    <h1>{title}</h1>
                    {this.state.searchType !== "birthdate" ? <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/> :
                        <TextField text='Suchwort' type='date' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/>}
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        {searchTypes.map((searchType,index) => (
                            <MenuItem key={index} value={searchType.value}>
                                {searchType.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <ToolButton className='search-button' main onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownItems.map((item,index) => (
                        <MasterDataItem
                            key={index}
                            item={item}
                            showItemDialog={this.showItemDialog}
                            deleteItem={() => this.showDeleteItemDialog(item)}
                            type={this.props.type}
                            formatDate={this.props.formatDate}
                        />
                    ))}
                </div>

                <ToolFab className='add-fab' onClick={() => this.showItemDialog(true,blankItem)} aria-label="add">
                    <AddIcon />
                </ToolFab>
            </div>
        )
    }
}

export default MasterDataContent;