import React, {Component} from "react";
import './Chat.css';

//Components
import ToolFab from "../ToolFab/ToolFab";
import ChatIcon from '@mui/icons-material/Chat';
import ChatDialog from "./ChatDialog/ChatDialog";

class Chat extends Component {

    state = {
        showChatDialog:false
    }

    showChatDialog = () => {
        this.props.setNewMessage(false);
        this.setState({showChatDialog:true});
    }

    closeChatDialog = () => {
        this.setState({showChatDialog:false});
    }

    render() {
        return (
            <div className="chat">
                <ToolFab onClick={this.showChatDialog} className={this.props.newMessage ? "chat-fab new-message" : "chat-fab"}><ChatIcon/></ToolFab>
                <ChatDialog
                    open={this.state.showChatDialog}
                    close={this.closeChatDialog}
                    user={this.props.user}
                    chatData={this.props.chatData}
                    logout={this.props.logout}
                    showMessage={this.props.showMessage}
                    company={this.props.company}
                    formatDateTime={this.props.formatDateTime}
                    setNewMessage={this.setNewMessage}
                />
            </div>
        )
    }
}

export default Chat;