import React, {Component} from "react";
import './TripDialog.css';

//Components
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import CloseIcon from '@mui/icons-material/Close';
import ReactTooltip from "react-tooltip";
import DialogContent from "@mui/material/DialogContent";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ChangeRequestDialog from "./ChangeRequestDialog/ChangeRequestDialog";

class TripDialog extends Component {

    state = {
        trip:this.props.trip,
        today:null,
        stations:[],

        //Field checks
        patientFirstNameCheck:true,
        patientLastNameCheck:true,
        patientBirthdateCheck:true,
        pickUpTimeCheck:true,
        startDateCheck:true,
        mondayCheck:true,
        tuesdayCheck:true,
        wednesdayCheck:true,
        thursdayCheck:true,
        fridayCheck:true,
        saturdayCheck:true,
        sundayCheck:true,
        mondayTimeCheck:true,
        tuesdayTimeCheck:true,
        wednesdayTimeCheck:true,
        thursdayTimeCheck:true,
        fridayTimeCheck:true,
        saturdayTimeCheck:true,
        sundayTimeCheck:true,
        locationNameFromCheck:true,
        stationNameFromCheck:true,
        streetNameFromCheck:true,
        streetNrFromCheck:true,
        postCodeFromCheck:true,
        cityFromCheck:true,
        countryFromCheck:true,
        locationNameToCheck:true,
        stationNameToCheck:true,
        streetNameToCheck:true,
        streetNrToCheck:true,
        postCodeToCheck:true,
        cityToCheck:true,
        countryToCheck:true,
        healthInsuranceCheck:true,
        transportKindCheck:true,
        tripTypeCheck:true,
        returnTripPickUpTimeCheck:true,
        returnTripMondayCheck:true,
        returnTripTuesdayCheck:true,
        returnTripWednesdayCheck:true,
        returnTripThursdayCheck:true,
        returnTripFridayCheck:true,
        returnTripSaturdayCheck:true,
        returnTripSundayCheck:true,
        returnTripMondayTimeCheck:true,
        returnTripTuesdayTimeCheck:true,
        returnTripWednesdayTimeCheck:true,
        returnTripThursdayTimeCheck:true,
        returnTripFridayTimeCheck:true,
        returnTripSaturdayTimeCheck:true,
        returnTripSundayTimeCheck:true,
        returnTripLocationNameFromCheck:true,
        returnTripStationNameFromCheck:true,
        returnTripStreetNameFromCheck:true,
        returnTripStreetNrFromCheck:true,
        returnTripPostCodeFromCheck:true,
        returnTripCityFromCheck:true,
        returnTripCountryFromCheck:true,
        returnTripLocationNameToCheck:true,
        returnTripStationNameToCheck:true,
        returnTripStreetNameToCheck:true,
        returnTripStreetNrToCheck:true,
        returnTripPostCodeToCheck:true,
        returnTripCityToCheck:true,
        returnTripCountryToCheck:true,
        returnTripHealthInsuranceCheck:true,
        returnTripTransportKindCheck:true,
        returnTripTripTypeCheck:true,

        // Dialog actions
        showSaveTripRequestDialog:false,
        showCompleteTripDialog:false,
        showChangeRequestDialog:false,
        showConfirmChangedTripRequestDialog:false,
        showRejectChangedTripRequestDialog:false
    }

    componentDidMount() {
        if(this.props.type === "trip-series") {
            this.setState({today:new Date().toDateString()});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({trip:this.props.trip});
        }
    }

    formatStationNames = (stations) => {
        let stationNames = [];
        for(let i = 0; i < stations.length; i++) {
            stationNames.push(stations[i].name);
        }
        return stationNames;
    }

    patientOnChangeHandler = (patient) => {
        let trip = this.state.trip;
        trip.patientFirstName = patient && patient.firstName ? patient.firstName : trip.patientFirstName;
        trip.patientLastName = patient && patient.lastName ? patient.lastName : trip.patientLastName;
        trip.patientBirthdate = patient && patient.birthdate ? patient.birthdate : trip.patientBirthdate;
        trip.newPatient = false;
        this.setState({trip:trip,patientFirstNameCheck:true,patientLastNameCheck:true,patientBirthdateCheck:true});
    }

    patientFirstNameOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.patientFirstName = e.target.value;
        trip.newPatient = true;
        this.setState({trip:trip,patientFirstNameCheck:true});
    }

    patientLastNameOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.patientLastName = e.target.value;
        trip.newPatient = true;
        this.setState({trip:trip,patientLastNameCheck:true});
    }

    patientBirthdateOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.patientBirthdate = e.target.value;
        trip.newPatient = true;
        this.setState({trip:trip,patientBirthdateCheck:true});
    }

    toggleTripSeries = () => {
        let trip = this.state.trip;
        trip.tripSeries = !trip.tripSeries;
        this.setState({trip:trip});
    }

    startDateOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.startDate = e.target.value;
        this.setState({trip:trip,startDateCheck:true});
    }

    endDateOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.endDate = e.target.value;
        this.setState({trip:trip});
    }

    toggleMonday = () => {
        let trip = this.state.trip;
        trip.monday = !trip.monday;
        this.setState({trip:trip,mondayCheck:true});
    }

    mondayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.mondayTime = e.target.value;
        this.setState({trip:trip,mondayTimeCheck:true});
    }

    toggleTuesday = () => {
        let trip = this.state.trip;
        trip.tuesday = !trip.tuesday;
        this.setState({trip:trip,tuesdayCheck:true});
    }

    tuesdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.tuesdayTime = e.target.value;
        this.setState({trip:trip,tuesdayTimeCheck:true});
    }

    toggleWednesday = () => {
        let trip = this.state.trip;
        trip.wednesday = !trip.wednesday;
        this.setState({trip:trip,wednesdayCheck:true});
    }

    wednesdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.wednesdayTime = e.target.value;
        this.setState({trip:trip,wednesdayTimeCheck:true});
    }

    toggleThursday = () => {
        let trip = this.state.trip;
        trip.thursday = !trip.thursday;
        this.setState({trip:trip,thursdayCheck:true});
    }

    thursdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.thursdayTime = e.target.value;
        this.setState({trip:trip,thursdayTimeCheck:true});
    }

    toggleFriday = () => {
        let trip = this.state.trip;
        trip.friday = !trip.friday;
        this.setState({trip:trip,fridayCheck:true});
    }

    fridayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.fridayTime = e.target.value;
        this.setState({trip:trip,fridayTimeCheck:true});
    }

    toggleSaturday = () => {
        let trip = this.state.trip;
        trip.saturday = !trip.saturday;
        this.setState({trip:trip,saturdayCheck:true});
    }

    saturdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.saturdayTime = e.target.value;
        this.setState({trip:trip,saturdayTimeCheck:true});
    }

    toggleSunday = () => {
        let trip = this.state.trip;
        trip.sunday = !trip.sunday;
        this.setState({trip:trip,sundayCheck:true});
    }

    sundayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.sundayTime = e.target.value;
        this.setState({trip:trip,sundayTimeCheck:true});
    }

    pickUpTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.pickUpTime = e.target.value;
        if(e.target.value) {
            let hours = e.target.value.substring(11,13);
            hours = parseInt(hours) + 1;
            if(hours < 24) {
                if(hours < 10) {
                    hours = "0" + hours;
                }
                let formattedDateTime = e.target.value.substring(0,11) + hours + e.target.value.substring(13,16);
                trip.pickUpTimeRange = formattedDateTime;
            }
        }
        this.setState({trip:trip,pickUpTimeCheck:true});
    }

    pickUpTimeRangeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.pickUpTimeRange = e.target.value;
        this.setState({trip:trip});
    }

    changeRequestPickUpTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.changeRequestPickUpTime = e.target.value;
        if(e.target.value) {
            let hours = e.target.value.substring(11,13);
            hours = parseInt(hours) + 1;
            if(hours < 24) {
                if(hours < 10) {
                    hours = "0" + hours;
                }
                let formattedDateTime = e.target.value.substring(0,11) + hours + e.target.value.substring(13,16);
                trip.changeRequestPickUpTimeRange = formattedDateTime;
            }
        }
        this.setState({trip:trip});
    }

    changeRequestPickUpTimeRangeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.changeRequestPickUpTimeRange = e.target.value;
        this.setState({trip:trip});
    }

    changeRequestEndDateOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.changeRequestEndDate = e.target.value;
        this.setState({trip:trip});
    }

    locationNameFromOnChangeHandler = (value) => {
        let trip = this.state.trip;
        trip.locationNameFrom = value;
        if(value === "Krankenhaus" || value === "Praxis") {
            if(!trip.streetNameFrom) {
                trip.streetNameFrom = this.props.company.companyInformation.streetName;
            }
            if(!trip.streetNrFrom) {
                trip.streetNrFrom = this.props.company.companyInformation.streetNr;
            }
            if(!trip.addressAdditionFrom) {
                trip.addressAdditionFrom = this.props.company.companyInformation.addressAddition;
            }
            if(!trip.postCodeFrom) {
                trip.postCodeFrom = this.props.company.companyInformation.postCode;
            }
            if(!trip.cityFrom) {
                trip.cityFrom = this.props.company.companyInformation.city;
            }
        }
        this.setState({trip:trip,locationNameFromCheck:true});
    }

    stationNameFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.stationNameFrom = e.target.value;
        this.setState({trip:trip,stationNameFromCheck:true});
    }

    streetNameFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.streetNameFrom = e.target.value;
        this.setState({trip:trip,streetNameFromCheck:true});
    }

    streetNrFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.streetNrFrom = e.target.value;
        this.setState({trip:trip,streetNrFromCheck:true});
    }

    addressAdditionFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.addressAdditionFrom = e.target.value;
        this.setState({trip:trip});
    }

    postCodeFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.postCodeFrom = e.target.value;
        this.setState({trip:trip,postCodeFromCheck:true});
    }

    cityFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.cityFrom = e.target.value;
        this.setState({trip:trip,cityFromCheck:true});
    }

    countryFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.countryFrom = e.target.value;
        this.setState({trip:trip,countryFromCheck:true});
    }

    arrivalTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.arrivalTime = e.target.value;
        this.setState({trip:trip});
    }

    locationNameToOnChangeHandler = (value) => {
        let trip = this.state.trip;
        trip.locationNameTo = value;
        if(value === "Krankenhaus" || value === "Praxis") {
            if(!trip.streetNameTo) {
                trip.streetNameTo = this.props.company.companyInformation.streetName;
            }
            if(!trip.streetNrTo) {
                trip.streetNrTo = this.props.company.companyInformation.streetNr;
            }
            if(!trip.addressAdditionTo) {
                trip.addressAdditionTo = this.props.company.companyInformation.addressAddition;
            }
            if(!trip.postCodeTo) {
                trip.postCodeTo = this.props.company.companyInformation.postCode;
            }
            if(!trip.cityTo) {
                trip.cityTo = this.props.company.companyInformation.city;
            }
        }
        this.setState({trip:trip,locationNameToCheck:true});
    }

    stationNameToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.stationNameTo = e.target.value;
        this.setState({trip:trip,stationNameToCheck:true});
    }

    streetNameToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.streetNameTo = e.target.value;
        this.setState({trip:trip,streetNameToCheck:true});
    }

    streetNrToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.streetNrTo = e.target.value;
        this.setState({trip:trip,streetNrToCheck:true});
    }

    addressAdditionToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.addressAdditionTo = e.target.value;
        this.setState({trip:trip});
    }

    postCodeToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.postCodeTo = e.target.value;
        this.setState({trip:trip,postCodeToCheck:true});
    }

    cityToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.cityTo = e.target.value;
        this.setState({trip:trip,cityToCheck:true});
    }

    countryToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.countryTo = e.target.value;
        this.setState({trip:trip,countryToCheck:true});
    }

    toggleCompanion = () => {
        let trip = this.state.trip;
        trip.companion = !trip.companion;
        this.setState({trip:trip});
    }

    toggleInfection = () => {
        let trip = this.state.trip;
        trip.infection = !trip.infection;
        this.setState({trip:trip});
    }

    costUnitOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.costUnit = e.target.value;
        this.setState({trip:trip});
    }

    healthInsuranceOnChangeHandler = (value) => {
        let trip = this.state.trip;
        trip.healthInsurance = value;
        this.setState({trip:trip,healthInsuranceCheck:true});
    }

    transportKindOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.transportKind = e.target.value;
        this.setState({trip:trip,transportKindCheck:true});
    }

    tripTypeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.tripType = e.target.value;
        this.setState({trip:trip,tripTypeCheck:true});
    }

    commentOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.comment = e.target.value;
        this.setState({trip:trip});
    }

    // Return Trip

    toggleReturnTrip = () => {
        let trip = this.state.trip;
        if(!trip.returnTrip) {
            trip.returnTripLocationNameFrom = trip.locationNameTo;
            trip.returnTripStationNameFrom = trip.stationNameTo;
            trip.returnTripStreetNameFrom = trip.streetNameTo;
            trip.returnTripStreetNrFrom = trip.streetNrTo;
            trip.returnTripAddressAdditionFrom = trip.addressAdditionTo;
            trip.returnTripPostCodeFrom = trip.postCodeTo;
            trip.returnTripCityFrom = trip.cityTo;
            trip.returnTripCountryFrom = trip.countryTo;
            trip.returnTripLocationNameTo = trip.locationNameFrom;
            trip.returnTripStreetNameTo = trip.streetNameFrom;
            trip.returnTripStreetNrTo = trip.streetNrFrom;
            trip.returnTripAddressAdditionTo = trip.addressAdditionFrom;
            trip.returnTripPostCodeTo = trip.postCodeFrom;
            trip.returnTripCityTo = trip.cityFrom;
            trip.returnTripCountryTo = trip.countryFrom;
            trip.returnTripTransportKind = trip.transportKind;
            trip.returnTripCompanion = trip.companion;
            trip.returnTripCostUnit = trip.costUnit;
            trip.returnTripInfection = trip.infection;
            trip.returnTripComment = trip.comment;
            if(trip.tripSeries) {
                trip.returnTripMonday = trip.monday;
                trip.returnTripTuesday = trip.tuesday;
                trip.returnTripWednesday = trip.wednesday;
                trip.returnTripThursday = trip.thursday;
                trip.returnTripFriday = trip.friday;
                trip.returnTripSaturday = trip.saturday;
                trip.returnTripSunday = trip.sunday;
                trip.returnTripMondayTime = trip.mondayTime;
                trip.returnTripTuesdayTime = trip.tuesdayTime;
                trip.returnTripWednesdayTime = trip.wednesdayTime;
                trip.returnTripThursdayTime = trip.thursdayTime;
                trip.returnTripFridayTime = trip.fridayTime;
                trip.returnTripSaturdayTime = trip.saturdayTime;
                trip.returnTripSundayTime = trip.sundayTime;
            }
        }else{
            trip.returnTripLocationNameFrom = null;
            trip.returnTripStationNameFrom = null;
            trip.returnTripStreetNameFrom = null;
            trip.returnTripStreetNrFrom = null;
            trip.returnTripAddressAdditionFrom = null;
            trip.returnTripPostCodeFrom = null;
            trip.returnTripCityFrom = null;
            trip.returnTripCountryFrom = null;
            trip.returnTripLocationNameTo = null;
            trip.returnTripStreetNameTo = null;
            trip.returnTripStreetNrTo = null;
            trip.returnTripAddressAdditionTo = null;
            trip.returnTripPostCodeTo = null;
            trip.returnTripCityTo = null;
            trip.returnTripCountryTo = null;
            trip.returnTripTransportKind = null;
            trip.returnTripCompanion = null;
            trip.returnTripCostUnit = null;
            trip.returnTripInfection = null;
            trip.returnTripComment = null;
            trip.returnTripMonday = null;
            trip.returnTripTuesday = null;
            trip.returnTripWednesday = null;
            trip.returnTripThursday = null;
            trip.returnTripFriday = null;
            trip.returnTripSaturday = null;
            trip.returnTripSunday = null;
            trip.returnTripMondayTime = null;
            trip.returnTripTuesdayTime = null;
            trip.returnTripWednesdayTime = null;
            trip.returnTripThursdayTime = null;
            trip.returnTripFridayTime = null;
            trip.returnTripSaturdayTime = null;
            trip.returnTripSundayTime = null;
        }
        trip.returnTrip = !trip.returnTrip;
        this.setState({trip:trip});
    }

    toggleReturnTripMonday = () => {
        let trip = this.state.trip;
        trip.returnTripMonday = !trip.returnTripMonday;
        this.setState({trip:trip,returnTripMondayCheck:true});
    }

    returnTripMondayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripMondayTime = e.target.value;
        this.setState({trip:trip,returnTripMondayTimeCheck:true});
    }

    toggleReturnTripTuesday = () => {
        let trip = this.state.trip;
        trip.returnTripTuesday = !trip.returnTripTuesday;
        this.setState({trip:trip,returnTripTuesdayCheck:true});
    }

    returnTripTuesdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripTuesdayTime = e.target.value;
        this.setState({trip:trip,returnTripTuesdayTimeCheck:true});
    }

    toggleReturnTripWednesday = () => {
        let trip = this.state.trip;
        trip.returnTripWednesday = !trip.returnTripWednesday;
        this.setState({trip:trip,returnTripWednesdayCheck:true});
    }

    returnTripWednesdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripWednesdayTime = e.target.value;
        this.setState({trip:trip,returnTripWednesdayTimeCheck:true});
    }

    toggleReturnTripThursday = () => {
        let trip = this.state.trip;
        trip.returnTripThursday = !trip.returnTripThursday;
        this.setState({trip:trip,returnTripThursdayCheck:true});
    }

    returnTripThursdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripThursdayTime = e.target.value;
        this.setState({trip:trip,returnTripThursdayTimeCheck:true});
    }

    toggleReturnTripFriday = () => {
        let trip = this.state.trip;
        trip.returnTripFriday = !trip.returnTripFriday;
        this.setState({trip:trip,returnTripFridayCheck:true});
    }

    returnTripFridayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripFridayTime = e.target.value;
        this.setState({trip:trip,returnTripFridayTimeCheck:true});
    }

    toggleReturnTripSaturday = () => {
        let trip = this.state.trip;
        trip.returnTripSaturday = !trip.returnTripSaturday;
        this.setState({trip:trip,returnTripSaturdayCheck:true});
    }

    returnTripSaturdayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripSaturdayTime = e.target.value;
        this.setState({trip:trip,returnTripSaturdayTimeCheck:true});
    }

    toggleReturnTripSunday = () => {
        let trip = this.state.trip;
        trip.returnTripSunday = !trip.returnTripSunday;
        this.setState({trip:trip,returnTripSundayCheck:true});
    }

    returnTripSundayTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripSundayTime = e.target.value;
        this.setState({trip:trip,returnTripSundayTimeCheck:true});
    }

    returnTripPickUpTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripPickUpTime = e.target.value;
        if(e.target.value) {
            let hours = e.target.value.substring(11,13);
            hours = parseInt(hours) + 1;
            if(hours < 24) {
                if(hours < 10) {
                    hours = "0" + hours;
                }
                let formattedDateTime = e.target.value.substring(0,11) + hours + e.target.value.substring(13,16);
                trip.returnTripPickUpTimeRange = formattedDateTime;
            }
        }
        this.setState({trip:trip,returnTripPickUpTimeCheck:true});
    }

    returnTripPickUpTimeRangeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripPickUpTimeRange = e.target.value;
        this.setState({trip:trip});
    }

    returnTripChangeRequestPickUpTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripChangeRequestPickUpTime = e.target.value;
        if(e.target.value) {
            let hours = e.target.value.substring(11,13);
            hours = parseInt(hours) + 1;
            if(hours < 24) {
                if(hours < 10) {
                    hours = "0" + hours;
                }
                let formattedDateTime = e.target.value.substring(0,11) + hours + e.target.value.substring(13,16);
                trip.returnTripChangeRequestPickUpTimeRange = formattedDateTime;
            }
        }
        this.setState({trip:trip});
    }

    returnTripChangeRequestPickUpTimeRangeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripChangeRequestPickUpTimeRange = e.target.value;
        this.setState({trip:trip});
    }

    returnTripLocationNameFromOnChangeHandler = (value) => {
        let trip = this.state.trip;
        trip.returnTripLocationNameFrom = value;
        if(value === "Krankenhaus" || value === "Praxis") {
            if(!trip.returnTripStreetNameFrom) {
                trip.returnTripStreetNameFrom = this.props.company.companyInformation.streetName;
            }
            if(!trip.returnTripStreetNrFrom) {
                trip.returnTripStreetNrFrom = this.props.company.companyInformation.streetNr;
            }
            if(!trip.returnTripAddressAdditionFrom) {
                trip.returnTripAddressAdditionFrom = this.props.company.companyInformation.addressAddition;
            }
            if(!trip.returnTripPostCodeFrom) {
                trip.returnTripPostCodeFrom = this.props.company.companyInformation.postCode;
            }
            if(!trip.returnTripCityFrom) {
                trip.returnTripCityFrom = this.props.company.companyInformation.city;
            }
        }
        this.setState({trip:trip,returnTripLocationNameFromCheck:true});
    }

    returnTripStationNameFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripStationNameFrom = e.target.value;
        this.setState({trip:trip,returnTripStationNameFromCheck:true});
    }

    returnTripStreetNameFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripStreetNameFrom = e.target.value;
        this.setState({trip:trip,returnTripStreetNameFromCheck:true});
    }

    returnTripStreetNrFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripStreetNrFrom = e.target.value;
        this.setState({trip:trip,returnTripStreetNrFromCheck:true});
    }

    returnTripAddressAdditionFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripAddressAdditionFrom = e.target.value;
        this.setState({trip:trip});
    }

    returnTripPostCodeFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripPostCodeFrom = e.target.value;
        this.setState({trip:trip,returnTripPostCodeFromCheck:true});
    }

    returnTripCityFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripCityFrom = e.target.value;
        this.setState({trip:trip,returnTripCityFromCheck:true});
    }

    returnTripCountryFromOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripCountryFrom = e.target.value;
        this.setState({trip:trip,returnTripCountryFromCheck:true});
    }

    returnTripArrivalTimeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripArrivalTime = e.target.value;
        this.setState({trip:trip});
    }

    returnTripLocationNameToOnChangeHandler = (value) => {
        let trip = this.state.trip;
        trip.returnTripLocationNameTo = value;
        if(value === "Krankenhaus" || value === "Praxis") {
            if(!trip.returnTripStreetNameTo) {
                trip.returnTripStreetNameTo = this.props.company.companyInformation.streetName;
            }
            if(!trip.returnTripStreetNrTo) {
                trip.returnTripStreetNrTo = this.props.company.companyInformation.streetNr;
            }
            if(!trip.returnTripAddressAdditionTo) {
                trip.returnTripAddressAdditionTo = this.props.company.companyInformation.addressAddition;
            }
            if(!trip.returnTripPostCodeTo) {
                trip.returnTripPostCodeTo = this.props.company.companyInformation.postCode;
            }
            if(!trip.returnTripCityTo) {
                trip.returnTripCityTo = this.props.company.companyInformation.city;
            }
        }
        this.setState({trip:trip,returnTripLocationNameToCheck:true});
    }

    returnTripStationNameToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripStationNameTo = e.target.value;
        this.setState({trip:trip,returnTripStationNameToCheck:true});
    }

    returnTripStreetNameToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripStreetNameTo = e.target.value;
        this.setState({trip:trip,returnTripStreetNameToCheck:true});
    }

    returnTripStreetNrToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripStreetNrTo = e.target.value;
        this.setState({trip:trip,returnTripStreetNrToCheck:true});
    }

    returnTripAddressAdditionToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripAddressAdditionTo = e.target.value;
        this.setState({trip:trip});
    }

    returnTripPostCodeToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripPostCodeTo = e.target.value;
        this.setState({trip:trip,returnTripPostCodeToCheck:true});
    }

    returnTripCityToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripCityTo = e.target.value;
        this.setState({trip:trip,returnTripCityToCheck:true});
    }

    returnTripCountryToOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripCountryTo = e.target.value;
        this.setState({trip:trip,returnTripCountryToCheck:true});
    }

    returnTripToggleCompanion = () => {
        let trip = this.state.trip;
        trip.returnTripCompanion = !trip.companion;
        this.setState({trip:trip});
    }

    returnTripToggleInfection = () => {
        let trip = this.state.trip;
        trip.returnTripInfection = !trip.infection;
        this.setState({trip:trip});
    }

    returnTripCostUnitOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripCostUnit = e.target.value;
        this.setState({trip:trip,returnTripCostUnitCheck:true});
    }

    returnTripHealthInsuranceOnChangeHandler = (value) => {
        let trip = this.state.trip;
        trip.returnTripHealthInsurance = value;
        this.setState({trip:trip,returnTripHealthInsuranceCheck:true});
    }

    returnTripTransportKindOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripTransportKind = e.target.value;
        this.setState({trip:trip,returnTripTransportKindCheck:true});
    }

    returnTripTripTypeOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripTripType = e.target.value;
        this.setState({trip:trip,returnTripTripTypeCheck:true});
    }

    returnTripCommentOnChangeHandler = (e) => {
        let trip = this.state.trip;
        trip.returnTripComment = e.target.value;
        this.setState({trip:trip});
    }

    saveTripRequestOnClickHandler = (trip) => {
        let check = true;
        if(!trip.patientFirstName) {
            check = false;
            this.setState({patientFirstNameCheck:false});
        }
        if(!trip.patientLastName) {
            check = false;
            this.setState({patientLastNameCheck:false});
        }
        if(!trip.patientBirthdate) {
            check = false;
            this.setState({patientBirthdateCheck:false});
        }
        if(!trip.tripSeries && !trip.pickUpTime) {
            check = false;
            this.setState({pickUpTimeCheck:false});
        }
        if(trip.tripSeries) {
            if(!trip.startDate) {
                check = false;
                this.setState({startDateCheck:false});
            }
            if(!trip.monday && !trip.tuesday && !trip.wednesday &&! trip.thursday && !trip.friday && !trip.saturday && !trip.sunday) {
                check = false;
                this.setState({mondayCheck:false,tuesdayCheck:false,wednesdayCheck:false,thursdayCheck:false,fridayCheck:false,saturdayCheck:false,sundayCheck:false});
            }
            if(trip.monday && !trip.mondayTime) {
                check = false;
                this.setState({mondayTimeCheck:false});
            }
            if(trip.tuesday && !trip.tuesdayTime) {
                check = false;
                this.setState({tuesdayTimeCheck:false});
            }
            if(trip.wednesday && !trip.wednesdayTime) {
                check = false;
                this.setState({wednesdayTimeCheck:false});
            }
            if(trip.thursday && !trip.thursdayTime) {
                check = false;
                this.setState({thursdayTimeCheck:false});
            }
            if(trip.friday && !trip.fridayTime) {
                check = false;
                this.setState({fridayTimeCheck:false});
            }
            if(trip.saturday && !trip.saturdayTime) {
                check = false;
                this.setState({saturdayTimeCheck:false});
            }
            if(trip.sunday && !trip.sundayTime) {
                check = false;
                this.setState({sundayTimeCheck:false});
            }
        }
        if(!trip.locationNameFrom) {
            check = false;
            this.setState({locationNameFromCheck:false});
        }
        if(trip.locationNameFrom === "Krankenhaus" && !trip.stationNameFrom) {
            check = false;
            this.setState({stationNameFromCheck:false});
        }
        if(!trip.streetNameFrom) {
            check = false;
            this.setState({streetNameFromCheck:false});
        }
        if(!trip.streetNrFrom) {
            check = false;
            this.setState({streetNrFromCheck:false});
        }
        if(!trip.postCodeFrom) {
            check = false;
            this.setState({postCodeFromCheck:false});
        }
        if(!trip.cityFrom) {
            check = false;
            this.setState({cityFromCheck:false});
        }
        if(!trip.countryFrom) {
            check = false;
            this.setState({countryFromCheck:false});
        }
        if(!trip.locationNameTo) {
            check = false;
            this.setState({locationNameToCheck:false});
        }
        if(trip.locationNameTo === "Krankenhaus" && !trip.stationNameTo) {
            check = false;
            this.setState({stationNameToCheck:false});
        }
        if(!trip.streetNameTo) {
            check = false;
            this.setState({streetNameToCheck:false});
        }
        if(!trip.streetNrTo) {
            check = false;
            this.setState({streetNrToCheck:false});
        }
        if(!trip.postCodeTo) {
            check = false;
            this.setState({postCodeToCheck:false});
        }
        if(!trip.cityTo) {
            check = false;
            this.setState({cityToCheck:false});
        }
        if(!trip.countryTo) {
            check = false;
            this.setState({countryToCheck:false});
        }
        if(trip.costUnit === 2 && !trip.healthInsurance) {
            check = false;
            this.setState({healthInsuranceCheck:false});
        }
        if(!trip.transportKind) {
            check = false;
            this.setState({transportKindCheck:false});
        }
        if(!trip.tripType) {
            check = false;
            this.setState({tripTypeCheck:false});
        }
        if(trip.returnTrip) {
            if(!trip.tripSeries && !trip.returnTripPickUpTime) {
                check = false;
                this.setState({returnTripPickUpTimeCheck:false});
            }
            if(trip.tripSeries) {
                if(!trip.returnTripMonday && !trip.returnTripTuesday && !trip.returnTripWednesday &&! trip.returnTripThursday && !trip.returnTripFriday && !trip.returnTripSaturday && !trip.returnTripSunday) {
                    check = false;
                    this.setState({returnTripMondayCheck:false,returnTripTuesdayCheck:false,returnTripWednesdayCheck:false,returnTripThursdayCheck:false,returnTripFridayCheck:false,returnTripSaturdayCheck:false,returnTripSundayCheck:false});
                }
                if(trip.returnTripMonday && !trip.returnTripMondayTime) {
                    check = false;
                    this.setState({returnTripMondayTimeCheck:false});
                }
                if(trip.returnTripTuesday && !trip.returnTripTuesdayTime) {
                    check = false;
                    this.setState({returnTripTuesdayTimeCheck:false});
                }
                if(trip.returnTripWednesday && !trip.returnTripWednesdayTime) {
                    check = false;
                    this.setState({returnTripWednesdayTimeCheck:false});
                }
                if(trip.returnTripThursday && !trip.returnTripThursdayTime) {
                    check = false;
                    this.setState({returnTripThursdayTimeCheck:false});
                }
                if(trip.returnTripFriday && !trip.returnTripFridayTime) {
                    check = false;
                    this.setState({returnTripFridayTimeCheck:false});
                }
                if(trip.returnTripSaturday && !trip.returnTripSaturdayTime) {
                    check = false;
                    this.setState({returnTripSaturdayTimeCheck:false});
                }
                if(trip.returnTripSunday && !trip.returnTripSundayTime) {
                    check = false;
                    this.setState({returnTripSundayTimeCheck:false});
                }
            }
            if(!trip.returnTripLocationNameFrom) {
                check = false;
                this.setState({returnTripLocationNameFromCheck:false});
            }
            if(trip.returnTripLocationNameFrom === "Krankenhaus" && !trip.returnTripStationNameFrom) {
                check = false;
                this.setState({returnTripStationNameFromCheck:false});
            }
            if(!trip.returnTripStreetNameFrom) {
                check = false;
                this.setState({returnTripStreetNameFromCheck:false});
            }
            if(!trip.returnTripStreetNrFrom) {
                check = false;
                this.setState({returnTripStreetNrFromCheck:false});
            }
            if(!trip.returnTripPostCodeFrom) {
                check = false;
                this.setState({returnTripPostCodeFromCheck:false});
            }
            if(!trip.returnTripCityFrom) {
                check = false;
                this.setState({returnTripCityFromCheck:false});
            }
            if(!trip.returnTripCountryFrom) {
                check = false;
                this.setState({returnTripCountryFromCheck:false});
            }
            if(!trip.returnTripLocationNameTo) {
                check = false;
                this.setState({returnTripLocationNameToCheck:false});
            }
            if(trip.returnTripLocationNameTo === "Krankenhaus" && !trip.returnTripStationNameTo) {
                check = false;
                this.setState({returnTripStationNameToCheck:false});
            }
            if(!trip.returnTripStreetNameTo) {
                check = false;
                this.setState({returnTripStreetNameToCheck:false});
            }
            if(!trip.returnTripStreetNrTo) {
                check = false;
                this.setState({returnTripStreetNrToCheck:false});
            }
            if(!trip.returnTripPostCodeTo) {
                check = false;
                this.setState({returnTripPostCodeToCheck:false});
            }
            if(!trip.returnTripCityTo) {
                check = false;
                this.setState({returnTripCityToCheck:false});
            }
            if(!trip.returnTripCountryTo) {
                check = false;
                this.setState({returnTripCountryToCheck:false});
            }
            if(trip.returnTripCostUnit === 2 && !trip.returnTripHealthInsurance) {
                check = false;
                this.setState({returnTripHealthInsuranceCheck:false});
            }
            if(!trip.returnTripTransportKind) {
                check = false;
                this.setState({returnTripTransportKindCheck:false});
            }
            if(!trip.returnTripTripType) {
                check = false;
                this.setState({returnTripTripTypeCheck:false});
            }
        }
        if(check) {
            this.showSaveTripRequestDialog();
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    saveChangeRequestOnClickHandler = (trip) => {
        if(trip.changeRequestPickUpTime || trip.returnTripChangeRequestPickUpTime || trip.changeRequestEndDate) {
            this.props.saveChangeRequest(trip);
            this.closeChangeRequestDialog();
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }


    // Dialog actions

    showSaveTripRequestDialog = () => {
        this.setState({showSaveTripRequestDialog:true});
    }

    closeSaveTripRequestDialog = () => {
        this.setState({showSaveTripRequestDialog:false});
    }

    showConfirmTripRequestDialog = () => {
        this.setState({showConfirmTripRequestDialog:true});
    }

    closeConfirmTripRequestDialog = () => {
        this.setState({showConfirmTripRequestDialog:false});
    }

    showRejectTripRequestDialog = () => {
        this.setState({showRejectTripRequestDialog:true});
    }

    closeRejectTripRequestDialog = () => {
        this.setState({showRejectTripRequestDialog:false});
    }

    showCompleteTripDialog = () => {
        this.setState({showCompleteTripDialog:true});
    }

    closeCompleteTripDialog = () => {
        this.setState({showCompleteTripDialog:false});
    }

    showCompleteFirstTripOnlyDialog = () => {
        this.setState({showCompleteFirstTripOnlyDialog:true});
    }

    closeCompleteFirstTripOnlyDialog = () => {
        this.setState({showCompleteFirstTripOnlyDialog:false});
    }

    showChangeRequestDialog = () => {
        this.setState({showChangeRequestDialog:true});
    }

    closeChangeRequestDialog = () => {
        this.setState({showChangeRequestDialog:false});
    }

    showConfirmChangedTripRequestDialog = () => {
        this.setState({showConfirmChangedTripRequestDialog:true});
    }

    closeConfirmChangedTripRequestDialog = () => {
        this.setState({showConfirmChangedTripRequestDialog:false});
    }

    showRejectChangedTripRequestDialog = () => {
        this.setState({showRejectChangedTripRequestDialog:true});
    }

    closeRejectChangedTripRequestDialog = () => {
        this.setState({showRejectChangedTripRequestDialog:false});
    }

    render() {

        let defaultInputProps = {
            maxLength:45
        }

        let multilineInputProps = {
            maxLength:255
        }

        const filter = createFilterOptions({limit:30});

        return (
            <Dialog scroll='body' fullWidth open={this.props.open} onClose={this.props.close} className='trip-dialog'>
                <ReactTooltip/>

                <YesCancelDialog
                    open={this.state.showSaveTripRequestDialog}
                    close={this.closeSaveTripRequestDialog}
                    header='Anfragen'
                    text='Wollen Sie die Fahrt wirklich anfragen? Änderungen sind dann nicht mehr möglich.'
                    onClick={this.state.trip && this.state.trip.id ? () => this.props.updateTripRequest(this.state.trip) : () => this.props.createTripRequest(this.state.trip)}
                />

                <YesCancelDialog
                    open={this.state.showRejectTripRequestDialog}
                    close={this.closeRejectTripRequestDialog}
                    header='Ablehnen'
                    text='Wollen Sie die Fahrtanfrage wirklich ablehnen? Sie können dies nicht rückgängig machen.'
                    onClick={() => this.props.rejectTripRequest(this.state.trip)}
                />

                <YesCancelDialog
                    open={this.state.showConfirmTripRequestDialog}
                    close={this.closeConfirmTripRequestDialog}
                    header='Bestätigen'
                    text='Wollen Sie die Fahrtanfrage wirklich bestätigen? Sie können dies nicht rückgängig machen.'
                    onClick={() => this.props.confirmTripRequest(this.state.trip)}
                />

                <YesCancelDialog
                    open={this.state.showCompleteTripDialog}
                    close={this.closeCompleteTripDialog}
                    header='Abschließen'
                    text='Wollen Sie die Fahrtanfrage wirklich abschließen? Sie können dies nicht rückgängig machen.'
                    onClick={() => this.props.completeTrip(this.state.trip)}
                />

                <YesCancelDialog
                    open={this.state.showCompleteFirstTripOnlyDialog}
                    close={this.closeCompleteFirstTripOnlyDialog}
                    header='Hinfahrt Abschließen'
                    text='Wollen Sie die Hinfahrt wirklich abschließen? Sie können dies nicht rückgängig machen.'
                    onClick={() => this.props.completeFirstTripOnly(this.state.trip)}
                />

                <YesCancelDialog
                    open={this.state.showConfirmChangedTripRequestDialog}
                    close={this.closeConfirmChangedTripRequestDialog}
                    header='Änderungsanfrage bestätigen'
                    text='Wollen Sie die Änderungsanfrage wirklich bestätigen? Sie können dies nicht rückgängig machen.'
                    onClick={() => this.props.confirmChangedTrip(this.state.trip)}
                />

                <YesCancelDialog
                    open={this.state.showRejectChangedTripRequestDialog}
                    close={this.closeRejectChangedTripRequestDialog}
                    header='Änderungsanfrage ablehnen'
                    text='Wollen Sie die Änderungsanfrage wirklich ablehnen? Die Fahrtanfrage wird dadurch gelöscht und Sie können dies nicht rückgängig machen.'
                    onClick={() => this.props.rejectChangedTrip(this.state.trip)}
                />

                {this.props.type === "trip-request" || this.props.type === "trip-series" ? <ChangeRequestDialog
                    open={this.state.showChangeRequestDialog}
                    close={this.closeChangeRequestDialog}
                    showMessage={this.props.showMessage}
                    trip={this.state.trip}
                    formatDateTime={this.props.formatDateTime}
                    formatDate={this.props.formatDate}
                    saveChangeRequestOnClickHandler={this.saveChangeRequestOnClickHandler}
                    changeRequestPickUpTimeOnChangeHandler={this.changeRequestPickUpTimeOnChangeHandler}
                    changeRequestPickUpTimeRangeOnChangeHandler={this.changeRequestPickUpTimeRangeOnChangeHandler}
                    returnTripChangeRequestPickUpTimeOnChangeHandler={this.returnTripChangeRequestPickUpTimeOnChangeHandler}
                    returnTripChangeRequestPickUpTimeRangeOnChangeHandler={this.returnTripChangeRequestPickUpTimeRangeOnChangeHandler}
                    changeRequestEndDateOnChangeHandler={this.changeRequestEndDateOnChangeHandler}
                /> : null}

                <CloseIcon onClick={this.props.close} className='icon close-icon'/>
                <DialogTitle><h1>
                    {this.props.type === "trip-request" ? "Fahrtanfrage" : null}
                    {this.props.type === "trip" ? "Fahrt" : null}
                    {this.props.type === "trip-series" ? "Serienfahrt" : null}
                    {this.props.type === "completed-trip" ? "Abgeschlossene Fahrt" : null}
                </h1></DialogTitle>
                <DialogContent>
                    <div>
                        {this.props.type === "trip-request" && this.props.company && this.props.company.rights === 1 && this.state.trip && this.state.trip.id ? <div>
                            <h2>Aktionen</h2>
                            <PictureAsPdfIcon data-tip="Fahrtanfrage als PDF generieren" className="icon" onClick={() => this.props.generateTripRequestPDF(this.state.trip)}/>
                        </div> : null}
                        <h2>Patient</h2>
                        <Autocomplete
                            disabled={this.state.trip && this.state.trip.id}
                            value={this.state.trip && this.state.trip.patientFirstName ? this.state.trip.patientFirstName: ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.patientOnChangeHandler({
                                        firstName: newValue,
                                        lastName: this.state.trip.patientLastName,
                                        birthdate: this.state.trip.patientBirthdate
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    this.patientOnChangeHandler({
                                        firstName: newValue.inputValue,
                                        lastName: this.state.trip && this.state.trip.patientLastName ? this.state.trip.patientLastName : "",
                                        birthdate: this.state.trip && this.state.trip.patientBirthdate ? this.state.trip.patientBirthdate : ""
                                    });
                                } else {
                                    this.patientOnChangeHandler(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                return filtered;
                            }}
                            inputProps={defaultInputProps}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            className='field'
                            options={this.props.patients}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.firstName;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.firstName ? option.firstName : ""} {option.lastName ? option.lastName : ""} {option.birthdate ? "(" + this.props.formatDate(option.birthdate) + ")" : ""}</li>}
                            renderInput={(params) => (
                                <TextField error={!this.state.patientFirstNameCheck} fullWidth {...params} label="Vorname" onChange={this.patientFirstNameOnChangeHandler} />
                            )}
                        />
                        <Autocomplete
                            disabled={this.state.trip && this.state.trip.id}
                            value={this.state.trip && this.state.trip.patientLastName ? this.state.trip.patientLastName: ''}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.patientOnChangeHandler({
                                        firstName: this.state.trip.patientLastName,
                                        lastName: newValue,
                                        birthdate: this.state.trip.patientBirthdate
                                    });
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    this.patientOnChangeHandler({
                                        firstName: this.state.trip && this.state.trip.patientFirstName ? this.state.trip.patientFirstName : "",
                                        lastName: newValue.inputValue,
                                        birthdate: this.state.trip && this.state.trip.patientBirthdate ? this.state.trip.patientBirthdate : ""
                                    });
                                } else {
                                    this.patientOnChangeHandler(newValue);
                                }
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                return filtered;
                            }}
                            inputProps={defaultInputProps}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            className='field'
                            options={this.props.patients}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.lastName;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.firstName ? option.firstName : ""} {option.lastName ? option.lastName : ""} {option.birthdate ? "(" + this.props.formatDate(option.birthdate) + ")" : ""}</li>}
                            renderInput={(params) => (
                                <TextField error={!this.state.patientLastNameCheck} fullWidth {...params} label="Nachname" onChange={this.patientLastNameOnChangeHandler} />
                            )}
                        />
                        <TextField error={!this.state.patientBirthdateCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} type="date" className='field' helperText="Geburtsdatum" value={this.state.trip && this.state.trip.patientBirthdate ? this.state.trip.patientBirthdate : ""} onChange={this.patientBirthdateOnChangeHandler}/>
                    </div>
                    <div>
                        <h2>Abholung</h2>
                        <FormControlLabel disabled={this.state.trip && this.state.trip.id} className='field' control={<Checkbox onChange={this.toggleTripSeries} checked={this.state.trip && this.state.trip.tripSeries}/>} label="Serienfahrt" />
                        {this.state.trip && this.state.trip.tripSeries ? <div>
                            <div>
                                <TextField error={!this.state.startDateCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} type="date" className='field' helperText="Startdatum" value={this.state.trip && this.state.trip.startDate ? this.state.trip.startDate : ""} onChange={this.startDateOnChangeHandler}/>
                            </div>
                            <div>
                                <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} type="date" className='field' helperText="Enddatum (optional)" value={this.state.trip && this.state.trip.endDate ? this.state.trip.endDate : ""} onChange={this.endDateOnChangeHandler}/>
                                {this.state.trip && this.state.trip.changeRequest && this.state.trip.changeRequestEndDate ? <TextField fullWidth inputProps={defaultInputProps} disabled type="date" className='field' helperText={<p className="changed-info-text">Neues Enddatum (optional)</p>} value={this.state.trip && this.state.trip.changeRequestEndDate ? this.state.trip.changeRequestEndDate : ""}/> : null}
                            </div>
                            <div>
                                <FormControlLabel className='field' error={!this.state.mondayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleMonday} checked={this.state.trip && this.state.trip.monday}/>} label="Montag" />
                                {this.state.trip && this.state.trip.monday ? <TextField fullWidth error={!this.state.mondayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.mondayTime ? this.state.trip.mondayTime : ""} onChange={this.mondayTimeOnChangeHandler}/> : null}
                            </div>
                            <div>
                                <FormControlLabel className='field' error={!this.state.tuesdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleTuesday} checked={this.state.trip && this.state.trip.tuesday}/>} label="Dienstag" />
                                {this.state.trip && this.state.trip.tuesday ? <TextField fullWidth error={!this.state.tuesdayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.tuesdayTime ? this.state.trip.tuesdayTime : ""} onChange={this.tuesdayTimeOnChangeHandler}/> : null}
                            </div>
                            <div>
                                <FormControlLabel className='field' error={!this.state.wednesdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleWednesday} checked={this.state.trip && this.state.trip.wednesday}/>} label="Mittwoch" />
                                {this.state.trip && this.state.trip.wednesday ? <TextField fullWidth error={!this.state.wednesdayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.wednesdayTime ? this.state.trip.wednesdayTime : ""} onChange={this.wednesdayTimeOnChangeHandler}/> : null}
                            </div>
                            <div>
                                <FormControlLabel className='field' error={!this.state.thursdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleThursday} checked={this.state.trip && this.state.trip.thursday}/>} label="Donnerstag" />
                                {this.state.trip && this.state.trip.thursday ? <TextField fullWidth error={!this.state.thursdayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.thursdayTime ? this.state.trip.thursdayTime : ""} onChange={this.thursdayTimeOnChangeHandler}/> : null}
                            </div>
                            <div>
                                <FormControlLabel className='field' error={!this.state.fridayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleFriday} checked={this.state.trip && this.state.trip.friday}/>} label="Freitag" />
                                {this.state.trip && this.state.trip.friday ? <TextField fullWidth error={!this.state.fridayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.fridayTime ? this.state.trip.fridayTime : ""} onChange={this.fridayTimeOnChangeHandler}/> : null}
                            </div>
                            <div>
                                <FormControlLabel className='field' error={!this.state.saturdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleSaturday} checked={this.state.trip && this.state.trip.saturday}/>} label="Samstag" />
                                {this.state.trip && this.state.trip.saturday ? <TextField fullWidth error={!this.state.saturdayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.saturdayTime ? this.state.trip.saturdayTime : ""} onChange={this.saturdayTimeOnChangeHandler}/> : null}
                            </div>
                            <div>
                                <FormControlLabel className='field' error={!this.state.sundayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleSunday} checked={this.state.trip && this.state.trip.sunday}/>} label="Sonntag" />
                                {this.state.trip && this.state.trip.sunday ? <TextField fullWidth error={!this.state.sundayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.sundayTime ? this.state.trip.sundayTime : ""} onChange={this.sundayTimeOnChangeHandler}/> : null}
                            </div>
                        </div> : <div>
                            <TextField fullWidth error={!this.state.pickUpTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} type='datetime-local' className='field' helperText="Zeitpunkt" value={this.state.trip && this.state.trip.pickUpTime ? this.state.trip.pickUpTime : ""} onChange={this.pickUpTimeOnChangeHandler}/>
                            <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} type='datetime-local' className='field' helperText="spätester Zeitpunkt (optional)" value={this.state.trip && this.state.trip.pickUpTimeRange ? this.state.trip.pickUpTimeRange : ""} onChange={this.pickUpTimeRangeOnChangeHandler}/>
                            {this.state.trip && this.state.trip.changeRequest ?
                                <div>
                                    {this.state.trip.changeRequestPickUpTime ? <TextField fullWidth inputProps={defaultInputProps} disabled type="datetime-local" className='field' helperText={<p className="changed-info-text">Neuer Zeitpunkt</p>} value={this.state.trip && this.state.trip.changeRequestPickUpTime ? this.state.trip.changeRequestPickUpTime : ""}/> : null}
                                    {this.state.trip.changeRequestPickUpTimeRange ? <TextField fullWidth inputProps={defaultInputProps} disabled type="datetime-local" className='field' helperText={<p className="changed-info-text">Neuer spätester Zeitpunkt (optional)</p>} value={this.state.trip && this.state.trip.changeRequestPickUpTimeRange ? this.state.trip.changeRequestPickUpTimeRange : ""}/> : null}
                                </div> : null}
                        </div>}
                        <Autocomplete
                            disabled={this.state.trip && this.state.trip.id}
                            inputProps={defaultInputProps}
                            freeSolo
                            options={["Krankenhaus","Praxis","Wohnung"]}
                            className="field"
                            value={this.state.trip && this.state.trip.locationNameFrom ? this.state.trip.locationNameFrom : ""}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.locationNameFromOnChangeHandler(newValue);
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    this.locationNameFromOnChangeHandler(newValue.inputValue);
                                    //this.setState({isNewCustomer:true});
                                } else {
                                    this.locationNameFromOnChangeHandler(newValue);
                                    //this.setState({isNewCustomer:false});
                                }
                            }}
                            renderInput={(params) => <TextField error={!this.state.locationNameFromCheck} fullWidth onChange={(e) => this.locationNameFromOnChangeHandler(e.target.value)} {...params} label="Ortsbezeichnung" />}
                        />
                        {this.state.trip && this.state.trip.locationNameFrom === "Krankenhaus" ? this.state.trip.id ?
                            <p className="field station-info">Station: {this.state.trip.stationNameFrom}</p>
                            : <TextField error={!this.state.stationNameFromCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} value={this.state.trip && this.state.trip.stationNameFrom ? this.state.trip.stationNameFrom : null} onChange={this.stationNameFromOnChangeHandler} select className='field' label='Station'>
                            {this.props.stations ? this.formatStationNames(this.props.stations).map(stationName => (
                                <MenuItem value={stationName}>
                                    {stationName}
                                </MenuItem>
                            )) : null}
                        </TextField> : null}
                        <TextField fullWidth error={!this.state.streetNameFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Straße" value={this.state.trip && this.state.trip.streetNameFrom ? this.state.trip.streetNameFrom : ""} onChange={this.streetNameFromOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.streetNrFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Hausnummer" value={this.state.trip && this.state.trip.streetNrFrom ? this.state.trip.streetNrFrom : ""} onChange={this.streetNrFromOnChangeHandler}/>
                        <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Adresszusatz" helperText='optional' value={this.state.trip && this.state.trip.addressAdditionFrom ? this.state.trip.addressAdditionFrom : ""} onChange={this.addressAdditionFromOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.postCodeFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Postleitzahl" value={this.state.trip && this.state.trip.postCodeFrom ? this.state.trip.postCodeFrom : ""} onChange={this.postCodeFromOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.cityFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Stadt" value={this.state.trip && this.state.trip.cityFrom ? this.state.trip.cityFrom : ""} onChange={this.cityFromOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.countryFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label='Land' value={this.state.trip && this.state.trip.countryFrom ? this.state.trip.countryFrom : ""} select onChange={this.countryFromOnChangeHandler}>
                            {this.props.countries}
                        </TextField>
                    </div>
                    <div>
                        <h2>Ankunft</h2>
                        {this.state.trip && !this.state.trip.tripSeries ? <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='datetime-local' helperText="Zeit (optional)" value={this.state.trip && this.state.trip.arrivalTime ? this.state.trip.arrivalTime : ""} onChange={this.arrivalTimeOnChangeHandler}/> : null}
                        <Autocomplete
                            disabled={this.state.trip && this.state.trip.id}
                            inputProps={defaultInputProps}
                            freeSolo
                            options={["Krankenhaus","Praxis","Wohnung"]}
                            className="field"
                            value={this.state.trip && this.state.trip.locationNameTo ? this.state.trip.locationNameTo : ""}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    this.locationNameToOnChangeHandler(newValue);
                                } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    this.locationNameToOnChangeHandler(newValue.inputValue);
                                    //this.setState({isNewCustomer:true});
                                } else {
                                    this.locationNameToOnChangeHandler(newValue);
                                    //this.setState({isNewCustomer:false});
                                }
                            }}
                            renderInput={(params) => <TextField error={!this.state.locationNameToCheck} fullWidth onChange={(e) => this.locationNameToOnChangeHandler(e.target.value)} {...params} label="Ortsbezeichnung" />}
                        />

                        {this.state.trip && this.state.trip.locationNameTo === "Krankenhaus" ? this.state.trip.id ?
                            <p className="field station-info">Station: {this.state.trip.stationNameTo}</p> : <TextField fullWidth error={!this.state.stationNameToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} value={this.state.trip && this.state.trip.stationNameTo ? this.state.trip.stationNameTo : null} onChange={this.stationNameToOnChangeHandler} select className='field' label='Station'>
                            {this.props.stations ? this.formatStationNames(this.props.stations).map(stationName => (
                                <MenuItem value={stationName}>
                                    {stationName}
                                </MenuItem>
                            )) : null}
                        </TextField> : null}
                        <TextField fullWidth error={!this.state.streetNameToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Straße" value={this.state.trip && this.state.trip.streetNameTo ? this.state.trip.streetNameTo : ""} onChange={this.streetNameToOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.streetNrToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Hausnummer" value={this.state.trip && this.state.trip.streetNrTo ? this.state.trip.streetNrTo : ""} onChange={this.streetNrToOnChangeHandler}/>
                        <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Adresszusatz" helperText='optional' value={this.state.trip && this.state.trip.addressAdditionTo ? this.state.trip.addressAdditionTo : ""} onChange={this.addressAdditionToOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.postCodeToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Postleitzahl" value={this.state.trip && this.state.trip.postCodeTo ? this.state.trip.postCodeTo : ""} onChange={this.postCodeToOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.cityToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Stadt" value={this.state.trip && this.state.trip.cityTo ? this.state.trip.cityTo : ""} onChange={this.cityToOnChangeHandler}/>
                        <TextField fullWidth error={!this.state.countryToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label='Land' value={this.state.trip && this.state.trip.countryTo ? this.state.trip.countryTo : ""} select onChange={this.countryToOnChangeHandler}>
                            {this.props.countries}
                        </TextField>
                    </div>
                    <div>
                        <h2>Sonstiges</h2>
                        <FormControlLabel disabled={this.state.trip && this.state.trip.id} className='field' control={<Checkbox onChange={this.toggleCompanion} value={this.state.trip && this.state.trip.companion !== null}/>} label="Begleitperson" />
                        <FormControlLabel disabled={this.state.trip && this.state.trip.id} className='field' control={<Checkbox onChange={this.toggleInfection} value={this.state.trip && this.state.trip.infection}/>} label="Infektion" />
                        <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' value={this.state.trip && this.state.trip.costUnit ? this.state.trip.costUnit : ""} onChange={this.costUnitOnChangeHandler} select label='Kostenträger' helperText="optional">
                            <MenuItem key={1} value={1}>Patient</MenuItem>
                            <MenuItem key={2} value={2}>Krankenkasse</MenuItem>
                        </TextField>
                        {this.state.trip && this.state.trip.costUnit && this.state.trip.costUnit === 2 ?
                            <Autocomplete
                                disabled={this.state.trip && this.state.trip.id}
                                inputProps={defaultInputProps}
                                freeSolo
                                options={["Techniker Krankenkasse","Barmer Ersatzkasse","DAK-Gesundheit","AOK","IKK classic","Kaufmännische Krankenkasse","Knappschaft","Siemens-Betriebskrankenkasse","Handelskrankenkasse","Audi BKK"]}
                                className="field"
                                value={this.state.trip && this.state.trip.healthInsurance ? this.state.trip.healthInsurance : ""}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        this.healthInsuranceOnChangeHandler(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        this.healthInsuranceOnChangeHandler(newValue.inputValue);
                                        //this.setState({isNewCustomer:true});
                                    } else {
                                        this.healthInsuranceOnChangeHandler(newValue);
                                        //this.setState({isNewCustomer:false});
                                    }
                                }}
                                renderInput={(params) => <TextField fullWidth error={!this.state.healthInsuranceCheck} onChange={(e) => this.healthInsuranceOnChangeHandler(e.target.value)} {...params} label="Krankenkasse" />}
                            /> : null}
                        <TextField error={!this.state.transportKindCheck} fullWidth disabled={this.state.trip && this.state.trip.id} className='field' value={this.state.trip && this.state.trip.transportKind ? this.state.trip.transportKind : ""} onChange={this.transportKindOnChangeHandler} select label='Transportart'>
                            <MenuItem key={1} value={1}>Tragestuhl</MenuItem>
                            <MenuItem key={2} value={2}>liegend</MenuItem>
                            {this.props.station && this.props.station.walkable ? <MenuItem key={3} value={3}>gefähig</MenuItem> : null}
                        </TextField>
                        <TextField error={!this.state.tripTypeCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' value={this.state.trip && this.state.trip.tripType ? this.state.trip.tripType : ""} onChange={this.tripTypeOnChangeHandler} select label='Fahrttyp'>
                            <MenuItem key={1} value={1}>Einweisung</MenuItem>
                            <MenuItem key={2} value={2}>Entlassung</MenuItem>
                            <MenuItem key={3} value={3}>Sonstiges</MenuItem>
                        </TextField>
                        <TextField fullWidth disabled={this.state.trip && this.state.trip.id} helperText='optional' multiline inputProps={multilineInputProps} minRows={7} className='multiline' label="Kommentar" value={this.state.trip && this.state.trip.comment ? this.state.trip.comment : ""} onChange={this.commentOnChangeHandler}/>
                        {this.props.type === "trip-request" ? <FormControlLabel disabled={this.state.trip && this.state.trip.id} className='field' control={<Checkbox checked={this.state.trip ? this.state.trip.returnTrip : false} onChange={this.toggleReturnTrip}/>} label="Inklusive Rückfahrt" /> : null}
                    </div>

                    {this.state.trip && this.state.trip.returnTrip ?
                        <div>
                            <h1 className='return-trip-title'>Rückfahrt</h1>
                            <div>
                                <h2>Abholung</h2>
                                {this.state.trip && this.state.trip.tripSeries ? <div>
                                    <div>
                                        <FormControlLabel className='field' error={!this.state.mondayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleReturnTripMonday} checked={this.state.trip && this.state.trip.returnTripMonday}/>} label="Montag" />
                                        {this.state.trip && this.state.trip.returnTripMonday ? <TextField fullWidth error={!this.state.mondayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.returnTripMondayTime ? this.state.trip.returnTripMondayTime : ""} onChange={this.returnTripMondayTimeOnChangeHandler}/> : null}
                                    </div>
                                    <div>
                                        <FormControlLabel className='field' error={!this.state.tuesdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleReturnTripTuesday} checked={this.state.trip && this.state.trip.returnTripTuesday}/>} label="Dienstag" />
                                        {this.state.trip && this.state.trip.returnTripTuesday ? <TextField fullWidth error={!this.state.tuesdayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.returnTripTuesdayTime ? this.state.trip.returnTripTuesdayTime : ""} onChange={this.returnTripTuesdayTimeOnChangeHandler}/> : null}
                                    </div>
                                    <div>
                                        <FormControlLabel className='field' error={!this.state.wednesdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleReturnTripWednesday} checked={this.state.trip && this.state.trip.returnTripWednesday}/>} label="Mittwoch" />
                                        {this.state.trip && this.state.trip.returnTripWednesday ? <TextField error={!this.state.wednesdayTimeCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.returnTripWednesdayTime ? this.state.trip.returnTripWednesdayTime : ""} onChange={this.returnTripWednesdayTimeOnChangeHandler}/> : null}
                                    </div>
                                    <div>
                                        <FormControlLabel className='field' error={!this.state.thursdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleReturnTripThursday} checked={this.state.trip && this.state.trip.returnTripThursday}/>} label="Donnerstag" />
                                        {this.state.trip && this.state.trip.returnTripThursday ? <TextField fullWidth error={!this.state.thursdayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.returnTripThursdayTime ? this.state.trip.returnTripThursdayTime : ""} onChange={this.returnTripThursdayTimeOnChangeHandler}/> : null}
                                    </div>
                                    <div>
                                        <FormControlLabel className='field' error={!this.state.fridayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleReturnTripFriday} checked={this.state.trip && this.state.trip.returnTripFriday}/>} label="Freitag" />
                                        {this.state.trip && this.state.trip.returnTripFriday ? <TextField fullWidth error={!this.state.fridayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.returnTripFridayTime ? this.state.trip.returnTripFridayTime : ""} onChange={this.returnTripFridayTimeOnChangeHandler}/> : null}
                                    </div>
                                    <div>
                                        <FormControlLabel className='field' error={!this.state.saturdayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleReturnTripSaturday} checked={this.state.trip && this.state.trip.returnTripSaturday}/>} label="Samstag" />
                                        {this.state.trip && this.state.trip.returnTripSaturday ? <TextField fullWidth error={!this.state.saturdayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.returnTripSaturdayTime ? this.state.trip.returnTripSaturdayTime : ""} onChange={this.returnTripSaturdayTimeOnChangeHandler}/> : null}
                                    </div>
                                    <div>
                                        <FormControlLabel className='field' error={!this.state.sundayCheck} disabled={this.state.trip && this.state.trip.id} control={<Checkbox onChange={this.toggleReturnTripSunday} checked={this.state.trip && this.state.trip.returnTripSunday}/>} label="Sonntag" />
                                        {this.state.trip && this.state.trip.returnTripSunday ? <TextField fullWidth error={!this.state.sundayTimeCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='time' label="Zeit" value={this.state.trip && this.state.trip.returnTripSundayTime ? this.state.trip.returnTripSundayTime : ""} onChange={this.returnTripSundayTimeOnChangeHandler}/> : null}
                                    </div>
                                </div> : <div>
                                    <TextField error={!this.state.returnTripPickUpTimeCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='datetime-local' helperText="Abholzeit" value={this.state.trip.returnTripPickUpTime} onChange={this.returnTripPickUpTimeOnChangeHandler}/>
                                    <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='datetime-local' helperText="späteste Abholzeit (optional)" value={this.state.trip.returnTripPickUpTimeRange} onChange={this.returnTripPickUpTimeRangeOnChangeHandler}/>
                                    {this.state.trip && this.state.trip.changeRequest ?
                                        <div>
                                            <TextField fullWidth inputProps={defaultInputProps} disabled type="datetime-local" className='field' helperText={<p className="changed-info-text">Neue Abholzeit</p>} value={this.state.trip && this.state.trip.returnTripChangeRequestPickUpTime ? this.state.trip.returnTripChangeRequestPickUpTime : ""}/>
                                            <TextField fullWidth inputProps={defaultInputProps} disabled type="datetime-local" className='field' helperText={<p className="changed-info-text">Neue späteste Abholzeit (optional)</p>} value={this.state.trip && this.state.trip.returnTripChangeRequestPickUpTimeRange ? this.state.trip.returnTripChangeRequestPickUpTimeRange : ""}/>
                                        </div> : null}
                                </div>}
                                <Autocomplete
                                    disabled={this.state.trip && this.state.trip.id}
                                    inputProps={defaultInputProps}
                                    freeSolo
                                    options={["Krankenhaus","Praxis","Wohnung"]}
                                    className="field"
                                    value={this.state.trip.returnTripLocationNameFrom}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            this.returnTripLocationNameFromOnChangeHandler(newValue);
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            this.returnTripLocationNameFromOnChangeHandler(newValue.inputValue);
                                            //this.setState({isNewCustomer:true});
                                        } else {
                                            this.returnTripLocationNameFromOnChangeHandler(newValue);
                                            //this.setState({isNewCustomer:false});
                                        }
                                    }}
                                    renderInput={(params) => <TextField error={!this.state.returnTripLocationNameFromCheck} fullWidth onChange={(e) => this.returnTripLocationNameFromOnChangeHandler(e.target.value)} {...params} label="Ortsbezeichnung" />}
                                />

                                {this.state.trip && this.state.trip.returnTripLocationNameFrom === "Krankenhaus" ? this.state.trip.id ?
                                    <p className="field station-info">Station: {this.state.trip.returnTripStationNameFrom}</p> : <TextField error={!this.state.returnTripStationNameFromCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} value={this.state.trip && this.state.trip.returnTripStationNameFrom ? this.state.trip.returnTripStationNameFrom : null} onChange={this.returnTripStationNameFromOnChangeHandler} select className='field' label='Station'>
                                        {this.props.stations ? this.formatStationNames(this.props.stations).map(stationName => (
                                            <MenuItem value={stationName}>
                                                {stationName}
                                            </MenuItem>
                                        )) : null}
                                </TextField> : null}
                                <TextField fullWidth error={!this.state.returnTripStreetNameFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Straße" value={this.state.trip.returnTripStreetNameFrom} onChange={this.returnTripStreetNameFromOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripStreetNrFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Hausnummer" value={this.state.trip.returnTripStreetNrFrom} onChange={this.returnTripStreetNrFromOnChangeHandler}/>
                                <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Adresszusatz" helperText='optional' value={this.state.trip.returnTripAddressAdditionFrom} onChange={this.returnTripAddressAdditionFromOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripPostCodeFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Postleitzahl" value={this.state.trip.returnTripPostCodeFrom} onChange={this.returnTripPostCodeFromOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripCityFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Stadt" value={this.state.trip.returnTripCityFrom} onChange={this.returnTripCityFromOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripCountryFromCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label='Land' value={this.state.trip.returnTripCountryFrom} select onChange={this.returnTripCountryFromOnChangeHandler}>
                                    {this.props.countries}
                                </TextField>
                            </div>
                            <div>
                                <h2>Ankunft</h2>
                                {this.state.trip && !this.state.tripSeries ? <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' type='datetime-local' helperText="Zeit (optional)" value={this.state.trip.returnTripArrivalTime} onChange={this.returnTripArrivalTimeOnChangeHandler}/> : null}
                                <Autocomplete
                                    disabled={this.state.trip && this.state.trip.id}
                                    inputProps={defaultInputProps}
                                    freeSolo
                                    options={["Krankenhaus","Praxis","Wohnung"]}
                                    className="field"
                                    value={this.state.trip.returnTripLocationNameTo}
                                    onChange={(event, newValue) => {
                                        if (typeof newValue === 'string') {
                                            this.returnTripLocationNameToOnChangeHandler(newValue);
                                        } else if (newValue && newValue.inputValue) {
                                            // Create a new value from the user input
                                            this.returnTripLocationNameToOnChangeHandler(newValue.inputValue);
                                            //this.setState({isNewCustomer:true});
                                        } else {
                                            this.returnTripLocationNameToOnChangeHandler(newValue);
                                            //this.setState({isNewCustomer:false});
                                        }
                                    }}
                                    renderInput={(params) => <TextField error={!this.state.returnTripLocationNameToCheck} fullWidth onChange={(e) => this.returnTripLocationNameToOnChangeHandler(e.target.value)} {...params} label="Ortsbezeichnung" />}
                                />
                                {this.state.trip && this.state.trip.returnTripLocationNameTo === "Krankenhaus" ? this.state.trip.id ?
                                    <p className="field station-info">Station: {this.state.trip.returnTripStationNameTo}</p> :
                                    <TextField error={!this.state.returnTripStationNameFromCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} value={this.state.trip && this.state.trip.returnTripStationNameTo ? this.state.trip.returnTripStationNameTo : null} onChange={this.returnTripStationNameToOnChangeHandler} select className='field' label='Station'>
                                        {this.props.stations ? this.formatStationNames(this.props.stations).map(stationName => (
                                            <MenuItem value={stationName}>
                                                {stationName}
                                            </MenuItem>
                                        )) : null}
                                </TextField> : null}
                                <TextField fullWidth error={!this.state.returnTripStreetNameToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Straße" value={this.state.trip.returnTripStreetNameTo} onChange={this.returnTripStreetNameToOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripStreetNrToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Hausnummer" value={this.state.trip.returnTripStreetNrTo} onChange={this.returnTripStreetNrToOnChangeHandler}/>
                                <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Adresszusatz" helperText='optional' value={this.state.trip.returnTripAddressAdditionTo} onChange={this.returnTripAddressAdditionToOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripPostCodeToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Postleitzahl" value={this.state.trip.returnTripPostCodeTo} onChange={this.returnTripPostCodeToOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripCityToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label="Stadt" value={this.state.trip.returnTripCityTo} onChange={this.returnTripCityToOnChangeHandler}/>
                                <TextField fullWidth error={!this.state.returnTripCountryToCheck} inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' label='Land' value={this.state.trip.returnTripCountryTo} select onChange={this.returnTripCountryToOnChangeHandler}>
                                    {this.props.countries}
                                </TextField>
                            </div>
                            <div>
                                <h2>Sonstiges</h2>
                                <FormControlLabel disabled={this.state.trip && this.state.trip.id} className='field' control={<Checkbox onChange={this.returnTripToggleCompanion} value={this.state.trip.returnTripCompanion}/>} label="Begleitperson" />
                                <FormControlLabel disabled={this.state.trip && this.state.trip.id} className='field' control={<Checkbox onChange={this.returnTripToggleInfection} value={this.state.trip.returnTripInfection}/>} label="Infektion" />
                                <TextField fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' value={this.state.trip.returnTripCostUnit} onChange={this.returnTripCostUnitOnChangeHandler} select label='Kostenträger' helperText="optional">
                                    <MenuItem key={1} value={1}>Patient</MenuItem>
                                    <MenuItem key={2} value={2}>Krankenkasse</MenuItem>
                                </TextField>
                                {this.state.trip && this.state.trip.returnTripCostUnit && this.state.trip.returnTripCostUnit === 2 ?
                                    <Autocomplete
                                        disabled={this.state.trip && this.state.trip.id}
                                        inputProps={defaultInputProps}
                                        freeSolo
                                        options={["Techniker Krankenkasse","Barmer Ersatzkasse","DAK-Gesundheit","AOK","IKK classic","Kaufmännische Krankenkasse","Knappschaft","Siemens-Betriebskrankenkasse","Handelskrankenkasse","Audi BKK"]}
                                        className="field"
                                        value={this.state.trip && this.state.trip.returnTripHealthInsurance ? this.state.trip.returnTripHealthInsurance : ""}
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === 'string') {
                                                this.returnTripHealthInsuranceOnChangeHandler(newValue);
                                            } else if (newValue && newValue.inputValue) {
                                                // Create a new value from the user input
                                                this.returnTripHealthInsuranceOnChangeHandler(newValue.inputValue);
                                                //this.setState({isNewCustomer:true});
                                            } else {
                                                this.returnTripHealthInsuranceOnChangeHandler(newValue);
                                                //this.setState({isNewCustomer:false});
                                            }
                                        }}
                                        renderInput={(params) => <TextField error={!this.state.returnTripHealthInsuranceCheck} fullWidth onChange={(e) => this.returnTripHealthInsuranceOnChangeHandler(e.target.value)} {...params} label="Krankenkasse" />}
                                    /> : null}
                                <TextField error={!this.state.returnTripTransportKindCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' value={this.state.trip.returnTripTransportKind} onChange={this.returnTripTransportKindOnChangeHandler} select label='Transportart'>
                                    <MenuItem key={1} value={1}>Tragestuhl</MenuItem>
                                    <MenuItem key={2} value={2}>liegend</MenuItem>
                                    {this.props.station && this.props.station.walkable ? <MenuItem key={3} value={3}>gefähig</MenuItem> : null}
                                </TextField>
                                <TextField error={!this.state.returnTripTripTypeCheck} fullWidth inputProps={defaultInputProps} disabled={this.state.trip && this.state.trip.id} className='field' value={this.state.trip.returnTripTripType} onChange={this.returnTripTripTypeOnChangeHandler} select label='Fahrttyp'>
                                    <MenuItem key={1} value={1}>Einweisung</MenuItem>
                                    <MenuItem key={2} value={2}>Entlassung</MenuItem>
                                    <MenuItem key={3} value={3}>Sonstiges</MenuItem>
                                </TextField>
                                <TextField fullWidth disabled={this.state.trip && this.state.trip.id} helperText='optional' multiline inputProps={multilineInputProps} minRows={7} className='multiline' label="Kommentar" value={this.state.trip.returnTripComment} onChange={this.returnTripCommentOnChangeHandler}/>
                            </div>
                        </div> : null}
                </DialogContent>
                <DialogActions>
                    {this.state.trip && !this.state.trip.changeRequest && this.props.type === "trip-request" && this.props.company && this.props.company.rights === 1 ? <ToolButton disabled={this.state.trip && !this.state.trip.id} className='button' onClick={this.showRejectTripRequestDialog}>Ablehnen</ToolButton> : null}

                    {this.state.trip && this.state.trip.changeRequest && (this.props.type === "trip-request" || this.props.type === "trip-series") ? <p className="info-text">Laufende Änderungsanfrage</p> : null}
                    {this.state.trip && !this.state.trip.changeRequest && this.props.company && ((this.props.company.rights === 1 && this.props.type === "trip-request") || (this.props.company.rights === 2 && this.props.type === "trip-series")) ? <ToolButton disabled={(this.state.trip && !this.state.trip.id && this.props.type !== "trip-series")} className='button' onClick={this.showChangeRequestDialog}>Änderungsanfrage</ToolButton> : null}
                    {this.state.trip && !this.state.trip.changeRequest && this.props.company && ((this.props.company.rights === 1 && this.props.type === "trip-request") || (this.props.company.rights === 2 && this.props.type === "trip-series")) ? <ToolButton disabled={this.state.trip && !this.state.trip.id} className='button' main onClick={this.showConfirmTripRequestDialog}>Bestätigen</ToolButton> : null}

                    {this.props.type === "trip-request" && this.props.company && this.props.company.rights === 2 && this.state.trip && !this.state.trip.id ? <ToolButton className='button' main onClick={() => this.saveTripRequestOnClickHandler(this.state.trip)}>Anfragen</ToolButton> : null}
                    {this.state.trip && this.state.trip.changeRequest && this.props.company && ((this.props.company.rights === 2 && this.props.type === "trip-request") || (this.props.company.rights === 1 && this.props.type === "trip-series")) ? <ToolButton className='button' onClick={this.showRejectChangedTripRequestDialog}>Änderungsanfrage ablehnen</ToolButton> : null}
                    {this.state.trip && this.state.trip.changeRequest && this.props.company && ((this.props.company.rights === 2 && this.props.type === "trip-request") || (this.props.company.rights === 1 && this.props.type === "trip-series")) ? <ToolButton className='button' main onClick={this.showConfirmChangedTripRequestDialog}>Änderungsanfrage bestätigen</ToolButton> : null}

                    {this.state.trip && this.state.trip.returnTrip && !this.state.trip.firstTripCompleted && this.props.type === "trip" && this.props.company && this.props.company.rights === 1 ? <ToolButton disabled={this.state.trip && !this.state.trip.id} className='button' onClick={this.showCompleteFirstTripOnlyDialog}>Hinfahrt Abschließen</ToolButton> : null}
                    {this.props.type === "trip" && this.props.company && this.props.company.rights === 1 ? <ToolButton disabled={this.state.trip && !this.state.trip.id} className='button' main onClick={this.showCompleteTripDialog}>{this.state.trip && this.state.trip.returnTrip ? "Komplett " : null}Abschließen</ToolButton> : null}
                </DialogActions>
            </Dialog>
        )
    }
}

export default TripDialog;