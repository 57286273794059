import React, {Component} from "react";
import './Reporting.css';

//Components
import TextField from "@mui/material/TextField";
import ReactTooltip from "react-tooltip";
import api from "../../../../api";
import ReportDialog from "./ReportDialog/ReportDialog";
import ToolButton from "../../../../reusable/ToolButton/ToolButton";

class Reporting extends Component {

    state = {
        reportRequest: {
            fromDate:'',
            toDate:'',
            reportConditions:[]
        },
        companyReports:null,
        formattedResult:null,

        // Dialog
        showReportDialog:false
    }

    // On Change Handler

    fromDateOnChangeHandler = (e) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.fromDate = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    toDateOnChangeHandler = (e) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.toDate = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    addConditionOnClickHandler = () => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions.push({
            type:0,
            article:null,
            customer:null,
            applicationUser:null,
            operator:null
        });
        this.setState({reportRequest:reportRequest});
    }

    removeConditionOnClickHandler = (index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions.splice(index,1);
        this.setState({reportRequest:reportRequest});
    }

    reportRequestOnClickHandler = () => {
        if(this.state.reportRequest.fromDate !== '' && this.state.reportRequest.toDate !== '') {
            this.sendReportRequest(this.state.reportRequest);
        }else{
            this.props.showMessage(2,"Fehlende oder falsche Werte");
        }
    }

    sendReportRequest = (reportRequest) => {
        const url = '/report';
        api.post(url,reportRequest)
            .then(response => {
                this.showReportDialog(response.data);
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error.message);
            });
    }

    //Condition Handler

    typeOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].type = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    postCodeOnChangeHandler = (e) => {

    }

    articleOnChangeHandler = (article,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].article = article;
        this.setState({reportRequest:reportRequest});
    }

    customerOnChangeHandler = (customer,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].customer = customer;
        this.setState({reportRequest:reportRequest});
    }

    userOnChangeHandler = (applicationUser,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].applicationUser = applicationUser;
        this.setState({reportRequest:reportRequest});
    }

    operatorOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].operator = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    statusOnChangeHandler = (e,index) => {
        let reportRequest = this.state.reportRequest;
        reportRequest.reportConditions[index].status = e.target.value;
        this.setState({reportRequest:reportRequest});
    }

    // Dialog actions

    showReportDialog = (companyReports) => {
        this.setState({companyReports:companyReports,showReportDialog:true});
    }

    closeReportDialog = () => {
        this.setState({showReportDialog:false});
    }

    render() {
        return (
            <div className='reporting'>
                <ReactTooltip/>

                <ReportDialog
                    open={this.state.showReportDialog}
                    close={this.closeReportDialog}
                    round={this.props.round}
                    companyReports={this.state.companyReports}
                    formattedReport={this.state.formattedReport}
                    formatDate={this.props.formatDate}
                    formatString={this.props.formatString}
                    calculationData={this.state.calculationData}
                    reportRequest={this.state.reportRequest}

                    //Company
                    company={this.props.company}
                />

                <h1>Reporting</h1>
                <TextField className='date-input' type='date' helperText='Datum von' onChange={this.fromDateOnChangeHandler} />
                <TextField className='date-input' type='date' helperText='Datum bis' onChange={this.toDateOnChangeHandler} />
                <ToolButton main className='report-button' onClick={this.reportRequestOnClickHandler}>Report generieren</ToolButton>
            </div>
        )
    }
}

export default Reporting;