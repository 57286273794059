import React, {Component} from 'react';
import './CommonSettings.css';

//Components
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import api from "../../../../../api";
import FileDialog from "../../../../../reusable/FileDialog/FileDialog";
class CommonSettings extends Component {

    state = {
        user:this.props.user,
        hasChildren:false,

        // Dialog actions
        showUploadPictureDialog:false,
        showUploadIdFrontDialog:false,
        showUploadIdBackDialog:false,
        showUploadBusinessLicenceDialog:false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props !== prevProps) {
            this.setState({user:this.props.user});
        }
    }

    signatureOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.signature = e.target.value;
        this.setState({user:user});
    }

    companyNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.companyName = e.target.value;
        this.setState({user:user});
    }

    streetNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.streetName = e.target.value;
        this.setState({user:user});
    }

    streetNrOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.streetNr = e.target.value;
        this.setState({user:user});
    }

    addressAdditionOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.addressAddition = e.target.value;
        this.setState({user:user});
    }

    postCodeOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.postCode = e.target.value;
        this.setState({user:user});
    }

    cityOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.city = e.target.value;
        this.setState({user:user});
    }

    countryOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.country = e.target.value;
        this.setState({user:user});
    }

    taxNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.taxNumber = e.target.value;
        this.setState({user:user});
    }

    vatNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.vatNumber = e.target.value;
        this.setState({user:user});
    }

    salutationOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.salutation = e.target.value;
        this.setState({user:user});
    }

    firstNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.firstName = e.target.value;
        this.setState({user:user});
    }

    lastNameOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.lastName = e.target.value;
        this.setState({user:user});
    }

    birthDateOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.birthDate = e.target.value;
        this.setState({user:user});
    }

    nationalityOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.nationality = e.target.value;
        this.setState({user:user});
    }

    emailOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.email = e.target.value;
        this.setState({user:user});
    }

    phoneOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.phone = e.target.value;
        this.setState({user:user});
    }

    jacketSizeOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.jacketSize = e.target.value;
        this.setState({user:user});
    }

    tshirtSizeOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.tshirtSize = e.target.value;
        this.setState({user:user});
    }

    accountHolderOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.accountHolder = e.target.value;
        this.setState({user:user});
    }

    ibanOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.iban = e.target.value;
        this.setState({user:user});
    }

    bicOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.bic = e.target.value;
        this.setState({user:user});
    }

    plannedEmploymentStartOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.plannedEmploymentStart = e.target.value;
        this.setState({user:user});
    }

    desiredSalaryOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.desiredSalary = e.target.value;
        this.setState({user:user});
    }

    idNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.idNumber = e.target.value;
        this.setState({user:user});
    }

    truthToggle = (e) => {
        this.setState({truth:e.target.checked});
    }

    dataPrivacyToggle = (e) => {
        this.setState({dataPrivacy:e.target.checked});
    }

    hasChildrenToggle = () => {
        if(this.state.hasChildren) {
            let user = this.state.user;
            user.userInformation.children = [];
            this.setState({hasChildren:!this.state.hasChildren,user:user});
        }else{
            this.setState({hasChildren:!this.state.hasChildren});
        }
    }

    childrenCountOnChangeHandler = (e) => {
        let user = this.state.user;
        let children = [];
        let formattedNumber = e.target.value;
        if(formattedNumber > 20) {
            formattedNumber = 20;
            this.props.showMessage(2,"Es sind maximal 20 Kinder möglich");
        }
        for(let i = 0; i < formattedNumber; i++) {
            children.push({
                firstName:'',
                lastName:'',
                birtDate:''
            });
        }
        user.userInformation.children = children;
        this.setState({user:user});
    }

    taxIdOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.taxId = e.target.value;
        this.setState({user:user});
    }

    childAllowanceOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.childAllowance = e.target.value;
        this.setState({user:user});
    }

    churchTaxToggle = (e) => {
        let user = this.state.user;
        user.userInformation.churchTax = !user.churchTax;
        this.setState({user:user});
    }

    taxBracketOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.taxBracket = e.target.value;
        this.setState({user:user});
    }

    healthInsuranceOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.healthInsurance = e.target.value;
        this.setState({user:user});
    }

    insuranceNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.insuranceNumber = e.target.value;
        this.setState({user:user});
    }

    insuranceStatusOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.insuranceStatus = e.target.value;
        this.setState({user:user});
    }

    nationalInsuranceNumberOnChangeHandler = (e) => {
        let user = this.state.user;
        user.userInformation.nationalInsuranceNumber = e.target.value;
        this.setState({user:user});
    }

    childFirstNameOnChangeHandler = (e,index) => {
        let user = this.state.user;
        user.userInformation.children[index].firstName = e.target.value;
        this.setState({user:user});
    }

    childLastNameOnChangeHandler = (e,index) => {
        let user = this.state.user;
        user.userInformation.children[index].lastName = e.target.value;
        this.setState({user:user});
    }

    childBirthDateOnChangeHandler = (e,index) => {
        let user = this.state.user;
        user.userInformation.children[index].birthDate = e.target.value;
        this.setState({user:user});
    }

    uploadPicture = (user,multipartFile) => {
        let url = '/users/upload-file/picture';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.pictureFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    uploadIdFront = (user,multipartFile) => {
        let url = '/users/upload-file/id-front';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.idFrontFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    uploadIdBack = (user,multipartFile) => {
        let url = '/users/upload-file/id-back';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.idBackFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    uploadBusinessLicence = (user,multipartFile) => {
        let url = '/users/upload-file/business-licence';
        let formData = new FormData();
        formData.append("multipartFile",multipartFile);
        api.put(url,formData)
            .then(response => {
                this.props.showMessage(0,"Die Datei wurde erfolgreich hochgeladen");
                let user = this.state.user;
                user.userInformation.businessLicenceFileStorageLocation = response.data;
                this.setState({user:user});
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    updateUserInformation = (userInformation) => {
        let url = "/users/user-information";
        api.put(url,userInformation)
            .then(response => {
                this.props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert")
            })
            .catch(error => {
                this.props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
            });
    }

    displayEmployeeFileOnClickHandler = (fileStorageLocation) => {
        let pathFileRequest = {
            username: this.state.user.username,
            fileStorageLocation: fileStorageLocation
        }
        this.props.displayEmployeeFile(pathFileRequest);
    }

    // Dialog actions

    showUploadPictureDialog = () => {
        this.setState({showUploadPictureDialog:true});
    }

    closeUploadPictureDialog = () => {
        this.setState({showUploadPictureDialog:false});
    }

    showUploadIdFrontDialog = () => {
        this.setState({showUploadIdFrontDialog:true});
    }

    closeUploadIdFrontDialog = () => {
        this.setState({showUploadIdFrontDialog:false});
    }

    showUploadIdBackDialog = () => {
        this.setState({showUploadIdBackDialog:true});
    }

    closeUploadIdBackDialog = () => {
        this.setState({showUploadIdBackDialog:false});
    }

    showUploadBusinessLicenceDialog = () => {
        this.setState({showUploadBusinessLicenceDialog:true});
    }

    closeUploadBusinessLicenceDialog = () => {
        this.setState({showUploadBusinessLicenceDialog:false});
    }

    render() {
        return (
            <div className='common-settings'>

                <FileDialog
                    open={this.state.showUploadPictureDialog}
                    close={this.closeUploadPictureDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadPicture}
                    item={this.state.user}
                />

                <FileDialog
                    open={this.state.showUploadIdFrontDialog}
                    close={this.closeUploadIdFrontDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadIdFront}
                    item={this.state.user}
                />

                <FileDialog
                    open={this.state.showUploadIdBackDialog}
                    close={this.closeUploadIdBackDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadIdBack}
                    item={this.state.user}
                />

                <FileDialog
                    open={this.state.showUploadBusinessLicenceDialog}
                    close={this.closeUploadBusinessLicenceDialog}
                    showMessage={this.props.showMessage}
                    addNewFileToItem={this.uploadBusinessLicence}
                    item={this.state.user}
                />

                <h1>Allgemein</h1>
                <div className='surrunder'>
                </div>
                <ToolButton onClick={() => this.updateUserInformation(this.state.user.userInformation)} main className='save-button'>Speichern</ToolButton>
            </div>
        )
    }
}

export default CommonSettings;