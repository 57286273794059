import React, {Component} from "react";
import './ReportDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CompanyReport from "./CompanyReport/CompanyReport";
import ReactTooltip from "react-tooltip";

class ReportDialog extends Component {
    render() {
        return (
            <Dialog scroll='body' open={this.props.open} onClose={this.props.close} className='report-dialog'>
                <ReactTooltip/>
                <DialogTitle>Report von {this.props.reportRequest && this.props.companyReports ? this.props.formatDate(this.props.reportRequest.fromDate) + " bis " + this.props.formatDate(this.props.reportRequest.toDate) : ""}</DialogTitle>
                {this.props.companyReports ? <DialogContent>
                    {this.props.companyReports ? this.props.companyReports.map(companyReport => (
                        <CompanyReport
                            companyReport={companyReport}
                            formatDate={this.props.formatDate}
                            formatString={this.props.formatString}

                            //Company
                            company={this.props.company}
                        />
                    )) : null}
                </DialogContent> : null}
            </Dialog>
        )
    }
}

export default ReportDialog;