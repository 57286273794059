import React, {Component} from "react";
import "./DataChart.css";

//Components
import {CartesianGrid, Line, LineChart, Tooltip, XAxis} from "recharts";

class Dashboard extends Component {

    state = {
        screenWidth:null
    }

    componentDidMount() {
        this.setState({screenWidth:document.getElementById("App").offsetWidth})
    }

    render() {

        return (
            <div>
                {this.state.screenWidth ? <LineChart
                    width={this.state.screenWidth > 800 ? 640 : 480}
                    height={this.state.screenWidth > 800 ? 256 : 192}
                    data={this.props.formattedData}
                    className="data-chart"
                >
                    <XAxis dataKey="Datum" />
                    <Tooltip />
                    <CartesianGrid stroke="#f5f5f5" />
                    <Line type="monotone" dataKey="Abgeschlossene Fahrten" stroke="#FAB900" yAxisId={0} />
                </LineChart> : null}
            </div>
        )
    }
}

export default Dashboard;